import { observable, runInAction } from 'mobx';

import { DataResponse } from 'services/response';
import { IVendorServices, VendorFromSAP, VendorModel } from 'services/RawData/vendor_services';
import { message } from 'antd';

export type VendorStore = {
    // Type default response search
    searchVendorResponse: DataResponse<Array<VendorModel>>;
    vendorDetailResponse: DataResponse<VendorModel | undefined>;
    crudResponse: DataResponse<any>;
    // Vendor SAP
    listVendorSAPRes: DataResponse<VendorFromSAP[]>
};

export interface IVendorPresenter {
    createStore(): VendorStore;
    // Search Vendor
    searchVendor(store: VendorStore, data: object): Promise<void>;
    // Update Vendor
    updateVendor(store: VendorStore, data: object): Promise<void>;
    // Create Vendor
    createVendor(store: VendorStore, data: object): Promise<void>;
    // Delete Vendor
    deleteVendor(store: VendorStore, vendorId: string): Promise<void>;
    // get Vendor by vendorId
    getVendorDetail(store: VendorStore, vendorId: string): Promise<void>;
    // search nhà cung cấp từ SAP
    searchVendorSAP(store: VendorStore, data: object): Promise<void>;
}

export function createVendorPresenter({
    vendorServices,
}: {
    vendorServices: IVendorServices;
}): IVendorPresenter {
    return {
        createStore: () =>
            observable({
                searchVendorResponse: {
                    data: [],
                    paging: {
                        totalCount: 0,
                        totalPage: 0,
                        pageSize: 0,
                    },
                    message: '',
                    isSuccess: false,
                },
                vendorDetailResponse: {
                    data: undefined,
                    message: '',
                    isSuccess: false,
                },
                crudResponse: {
                    data: null,
                    message: '',
                    isSuccess: false,
                },
                listVendorSAPRes: {
                    data: [],
                    message: '',
                    isSuccess: false,
                }
            }),
        // Search vendor
        searchVendor: async (store, data) => {
            try {
                const result = await vendorServices.searchVendors(data);
                runInAction(() => {
                    store.searchVendorResponse.data = result.data;
                    store.searchVendorResponse.isSuccess = result.isSuccess;
                    store.searchVendorResponse.message = result.message;
                    store.searchVendorResponse.paging = {
                        totalCount: result.paging?.pagesCount,
                        recordsTotal: result.paging?.recordsTotal,
                        pageSize: result.paging?.resultsCount,
                    };
                });
            } catch (error) {
                console.log(error);
            }
        },
        updateVendor: async (store, data) => {
            try {
                const result = await vendorServices.updateVendor(data);
                runInAction(() => {
                    store.crudResponse.isSuccess = result.isSuccess;
                    store.crudResponse.message = result.message;
                });
            } catch (error) {
                console.log(error);
            }
        },
        createVendor: async (store, data) => {
            try {
                const result = await vendorServices.addVendor(data);
                runInAction(() => {
                    store.crudResponse.data = result.data;
                    store.crudResponse.isSuccess = result.isSuccess;
                    store.crudResponse.message = result.message;
                });
            } catch (error) {
                console.error(error);
            }
        },
        deleteVendor: async (store, code) => {
            try {
                const result = await vendorServices.deleteVendor(code);
                runInAction(() => {
                    store.crudResponse.isSuccess = result.isSuccess;
                    store.crudResponse.message = result.message;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getVendorDetail: async (store, EmployeeCode) => {
            try {
                const result = await vendorServices.getVendorById(EmployeeCode);
                runInAction(() => {
                    store.vendorDetailResponse.isSuccess = result.isSuccess;
                    store.vendorDetailResponse.data = result.data;
                    store.vendorDetailResponse.message = result.message;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // search nhà cung cấp từ SAP
        searchVendorSAP: async (store, data) => {
            try {
                const result = await vendorServices.searchVendorSAP(data);
                if (!result.isSuccess) {
                    message.error(result.message)
                }
                runInAction(() => {
                    store.listVendorSAPRes = result;
                });
            } catch (error) {
                console.log(error);
            }
        }
    };
}
