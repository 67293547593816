import { IServices } from 'services/services';
import { LayoutHOC } from 'base/ui/layout/layout';
import { createHomePage } from './dashboard/create';
import { createLoginPage } from './login/create';
import { createModulePage } from './module/create';
import { createPurchaseOrderPage } from './purchaseOrder/create';
import { createVehicleRegistrationPage } from './vehicleRegistration/create';
import { createIconPage } from './iconScreen/create';
import { createMasterDataPage } from './rawData/MasterData/create';
import { createPermissionPage } from './rawData/Permission/create';
import { createSynchPurchaseOrderPage } from './synchPO/create';
import { createReportPage } from './report/create';
import { createReceiveSchedulePage } from './receiveSchedule/create';
import { createVerifyPage } from './login/QRCodeVerify/create';
import { createPrintPDFPage } from './vehicleRegistration/PrintPDF/create';
import { createChangePasswordPage } from './changePassword/create';
import { createConnectMSPage } from './ConnectMS/create';



export function createPages(layoutHOC: LayoutHOC, services: IServices) {
  return {
    // ======== PAGE HỆ THỐNG =================================================
    HomePage: createHomePage(layoutHOC),
    LoginPage: createLoginPage(layoutHOC, services.userServices),
    // trang modules
    ModulePage: createModulePage(layoutHOC),
    // Xem icons
    IconsPage: createIconPage(layoutHOC),
    // Xác thực khi đăng nhập (quét QR Code)
    VerifyPage: createVerifyPage(layoutHOC),
    // đổi mật khẩu
    ChangePasswordPage: createChangePasswordPage(layoutHOC, services.accountServices),
    // kết nối Microsoft 
    ConnectMSPage: createConnectMSPage(layoutHOC),
    // ========================================================================

    //========= PAGE DỰ ÁN ==================================================
    // Danh sách po
    PurchaseOrderPage: createPurchaseOrderPage(layoutHOC, services),
    // Đăng ký giao hàng
    VehicleRegistrationPage: createVehicleRegistrationPage(layoutHOC),
    // Đồng bộ PO
    SynchPOPage: createSynchPurchaseOrderPage(layoutHOC),
    // BÁO CÁO 
    ReportPage: createReportPage(layoutHOC),
    // Lịch nhận hàng
    ReceiveSchedulePage: createReceiveSchedulePage(layoutHOC, services.purchaseOrderServices),

    PrintPDFPage: createPrintPDFPage(layoutHOC),
    // ======================================================================



    //  ========= PAGE DÙNG CHUNG ============================================
    // Master data
    MasterData: createMasterDataPage(layoutHOC),
    // Permission
    Permission: createPermissionPage(layoutHOC, services),
    // =======================================================================


  };
}
