import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const accountRoutes = ({
  Account,
  AddAccount,
  UpdateAccount,
  ChangePassword,
  ResetPassword,
  CreateVendorAccount
}: {
  Account: React.ComponentType;
  AddAccount: React.ComponentType;
  UpdateAccount: React.ComponentType;
  ChangePassword: React.ComponentType;
  ResetPassword: React.ComponentType;
  CreateVendorAccount: React.ComponentType;
}) => (
  <Routes>
    <Route path={''} element={<Account />} />
    <Route path={ROUTES.PERMISSION.ACCOUNT.CREATE} element={<AddAccount />} />
    <Route path={ROUTES.PERMISSION.ACCOUNT.EDIT} element={<UpdateAccount />} />
    <Route path={ROUTES.PERMISSION.ACCOUNT.CHANGE_PASSWORD} element={<ChangePassword />} />
    <Route path={ROUTES.PERMISSION.ACCOUNT.RESET_PASSWORD} element={<ResetPassword />} />
    <Route path={ROUTES.PERMISSION.ACCOUNT.CREATE_VENDOR_ACCOUNT} element={<CreateVendorAccount />} />
  </Routes>
);
