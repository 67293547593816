import { LayoutHOC } from "base/ui/layout/layout";
import { reportRoutes } from "./routes";
import createListRegistration from "./ListRigistration";
import createDeliveryResult from "./DeliveryResult";
import createDeliveryRate from "./DeliveryRate";
import createReceivingSchedule from "./ReceivingSchedule";

export const createReportPage = (layoutHOC: LayoutHOC) => {
    // Báo cáo danh sách đăng ký xe
    const RegistrationList = createListRegistration()
    // Báo cáo kết quả giao hàng
    const DeliveryResult = createDeliveryResult()
    // báo cáo tỷ lệ giáo hàng đúng hẹn
    const DeliveryRate = createDeliveryRate()
    // Receiving schedule of BHF
    const ReceivingSchedule = createReceivingSchedule()

    const ReportPage = layoutHOC(reportRoutes);

    return () => (
        <ReportPage RegistrationList={RegistrationList} DeliveryResult={DeliveryResult} DeliveryRate={DeliveryRate} ReceivingSchedule={ReceivingSchedule}
        />
    );
};