import { deleted, get, post, put } from 'utils/api';
import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { CommonModel } from './commonMasterData_services';

export type DataAccount = {
  stt: number;
  accountId: string;
  employeeCode: string;
  employeeName: string;
  userName: string;
  fullName: string;
  email?: string;
  phone?: string;
  actived: boolean;
  password: string;
  listRoleName: string;
  isSupplier?: boolean;
  createBy?: string;
  createTime?: string;
  lastLogin?: string
};

export type AccountDetail = {
  accountId: string;
  employeeCode: number;
  userName: string;
  fullName: string;
  actived: boolean;
  password: string;
  listRoleId: string[];
  listStoreId: string[];
  isShowChoseModule: boolean;
  isShowDashboard: boolean;
  isViewByStore: boolean;
  isViewOpportunity: boolean | null;
  taskFilterCode: string;
  isCreatePrivateTask: boolean;
  appointmentPermission: boolean;
  isSupplier: boolean;
};

export interface IAccountServices {
  // Get account list
  getAccountList(keyword: string): Promise<DataResponse<Array<DataAccount>>>;
  // search account list
  searchAccount(data: object): Promise<DataResponse<Array<DataAccount>>>;
  // add an account
  addAccount(data: object): Promise<DataResponse<any>>;
  // update account list
  updateAccount(data: object): Promise<DataResponse<any>>;
  // lấy data dropdown trong nhóm người dùng
  getDropdownRoles(): Promise<DataResponse<CommonModel[]>>;
  // delete account by id
  deleteAccountById(AccountId: string): Promise<DataResponse<any>>;
  // lấy data tài khoản bằng id
  getAccountById(AccountId: string): Promise<DataResponse<AccountDetail>>;
  // thay đổi mật khẩu (admin)
  changePassword(data: object): Promise<DataResponse<any>>;
  // reset password
  resetPassword(data: { accountId: string; newPassword: string }): Promise<DataResponse<any>>;
  // user tự đổi mật khẩu
  changePasswordByUser(data: object): Promise<DataResponse<null>>
}

export function createAccountServices(): IAccountServices {
  return {
    // Get account list
    getAccountList: async (keyword) => {
      return (await get(MASTER_DATA_API_URL, `Permission/Account/get-by-keyword${keyword ? `?keyword=${keyword}` : ''}`))
        .data;
    },
    searchAccount: async (data) => {
      return (await post(MASTER_DATA_API_URL, `account/search`, data)).data;
    },
    addAccount: async (data) => {
      return (await post(MASTER_DATA_API_URL, `account/create`, data)).data;
    },
    updateAccount: async (data) => {
      return (await put(MASTER_DATA_API_URL, `account/update`, data)).data;
    },
    // lấy data dropdown trong nhóm người dùng
    getDropdownRoles: async () => {
      return (await get(MASTER_DATA_API_URL, `common/get-dropdown-role`)).data;
    },
    // lấy data tài khoản bằng id
    getAccountById: async (AccountId) => {
      return (
        await get(MASTER_DATA_API_URL, `account/${AccountId}`)
      ).data;
    },
    // xóa tài khoản bằng id
    deleteAccountById: async (AccountId) => {
      return (
        await deleted(MASTER_DATA_API_URL, `account/delete/${AccountId}`)
      ).data;
    },
    // thay đổi mật khẩu
    changePassword: async (data) => {
      return (await post(MASTER_DATA_API_URL, `account/change-password`, data)).data;
    },
    // reset password
    resetPassword: async (data) => {
      return (await post(MASTER_DATA_API_URL, `account/reset-password`, data)).data;
    },
    // user tự đổi mật khẩu
    changePasswordByUser: async (data) => {
      return (await post(MASTER_DATA_API_URL, `account/change-password-by-user`, data)).data;
    }
  };
}
