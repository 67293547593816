
import { Navigate, Route } from 'react-router-dom'


type CustomeRouteProps = {
    isAuthenticated: boolean
    children: JSX.Element
}

const PublicRoute = ({ children, isAuthenticated }: CustomeRouteProps) => {
    return isAuthenticated ? <Navigate to={'/'} /> : <>{children}</>
}
const PrivateRoute = ({ children, isAuthenticated }: CustomeRouteProps) => {
    return !isAuthenticated ? <Navigate to={'/login'} /> : <>{children}</>
}

export { PrivateRoute, PublicRoute }