import { message } from 'antd';
import { observable, runInAction } from 'mobx';
import { DeliveryRegistrationModel, HistoryEditModel } from 'models/DeliveryRegistration';
import { POCreatRequest, PurchaseOrderDetailModel, PurchaseOrderModel } from 'models/PurchaseOrder';
import { PurchaseOrderSAPModel, PurchaseOrderSAPRequest } from 'models/PurchaseOrderSAP';
import { CommonModel, ICommonServices } from 'services/Main/common_services';
import { IPurchaseOrderServices, SearchDeliveryRegistration, SearchHistory, SearchPO, SearchSynchPO } from 'services/Main/purchaseOrder_services';
import { DataResponse } from 'services/response';

export type PurchaseOrderStore = {
    purchseOrderRes: DataResponse<PurchaseOrderModel[]>
    // danh sách phiếu đăng ký phiêu giao hàng
    deliveryRegistrationRes: DataResponse<DeliveryRegistrationModel[]>
    // danh sách trạng thái luồn duyệt

    // Search màn đồng bộ PO
    searchSynchPO: DataResponse<Array<PurchaseOrderSAPModel>>
    // Lịch sử chỉnh sửa 
    dataHistory: DataResponse<HistoryEditModel[]>
    //
    detailPO?: POCreatRequest
};

export interface IPurchaseOrderPresenter {
    createStore(): PurchaseOrderStore;
    // search po
    getListPO(store: PurchaseOrderStore, data: SearchPO): Promise<void>;
    // Tạo phiếu đăng ký giao hàng
    createDeliveryRegistration(data: object): Promise<boolean>
    // chi tiết phiếu đăng ký giao hàng
    getDetailDeliveryRegistration(deliveryRegistrationId: string): Promise<PurchaseOrderDetailModel | undefined>;
    // cập nhật phiếu đăng ký giao hàng
    updateDeliveryRegistration(data: object, isHideMessage?: boolean): Promise<boolean>;
    // danh sách đăng ký giao hàng
    getListDeliveryRegistration(store: PurchaseOrderStore, data: SearchDeliveryRegistration): Promise<void>;
    // thực thi transition button
    executeTransitionButton(data: object): Promise<boolean>;
    // hủy phiếu 
    cancelDeliveryRegistration(data: { cancelReason: string; deliveryRegistrationId: string }): Promise<boolean>;
    // export excel màn danh sách PO
    exportListPO(request: Omit<SearchPO, 'paging'>): Promise<void>
    exportCreatePOTemplate(): Promise<void>
    // Cập nhật PO item
    updatePOItem(data: Pick<PurchaseOrderModel, 'purchaseOrderDetailId' | "note">): Promise<boolean>
    // Lịch sử chỉnh sửa 
    getHistory(store: PurchaseOrderStore, data: SearchHistory): Promise<void>
    // Tạo mới PO
    createPurchaseOrder(data: POCreatRequest): Promise<DataResponse>;
    // Cập nhật PO
    updatePurchaseOrder(data: POCreatRequest): Promise<DataResponse>;
    deletePurchaseOrder(id: string): Promise<DataResponse>;
    // Chi tiết PO
    getDetailsPurchaseOrder(store: PurchaseOrderStore, id: string): Promise<void>
    // --- ĐỒNG BỘ PO ----------------------------------------------------------------------------------------------------------------------------------
    // search PO SAP
    searchPOSAP(store: PurchaseOrderStore, data: SearchSynchPO): Promise<void>;
    // Đồng bộ po
    synchPO(data: { purchaseOrders: PurchaseOrderSAPRequest[] }): Promise<DataResponse<null>>;
}

export function createPurchaseOrderPresenter({ purchaseOrderServices }: { purchaseOrderServices: IPurchaseOrderServices }): IPurchaseOrderPresenter {
    return {
        createStore: (): PurchaseOrderStore =>
            observable({
                purchseOrderRes: {
                    data: [],
                    isSuccess: false,
                    message: ''
                },
                deliveryRegistrationRes: {
                    data: [],
                    isSuccess: false,
                    message: ''
                },
                // Search màn đồng bộ PO
                searchSynchPO: {
                    data: [],
                    isSuccess: false,
                    message: ''
                },
                // Lịch sử chỉnh sửa 
                dataHistory: {
                    data: [],
                    isSuccess: false,
                    message: ''
                },
            }),

        getListPO: async (store, data) => {
            try {
                const result = await purchaseOrderServices.getListPurchaseOrder(data);
                runInAction(() => {
                    store.purchseOrderRes = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // Tạo phiếu đăng ký giao hàng
        createDeliveryRegistration: async (data) => {
            try {
                const result = await purchaseOrderServices.createDeliveryRegistration(data);
                if (result.isSuccess) {
                    message.success(result.message);
                } else {
                    message.error(result.message);
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        // chi tiết phiếu đăng ký giao hàng
        getDetailDeliveryRegistration: async (deliveryRegistrationId) => {
            try {
                const result = await purchaseOrderServices.getDetailDeliveryRegistration(deliveryRegistrationId);
                if (!result.isSuccess) {
                    message.error(result.message);
                }
                return result.data
            } catch (error) {
                console.log(error);
                return undefined
            }
        },
        // cập nhật phiếu đăng ký giao hàng
        updateDeliveryRegistration: async (data, isHideMessage) => {
            try {
                const result = await purchaseOrderServices.updateDeliveryRegistration(data);
                if (!isHideMessage) {
                    if (result.isSuccess) {
                        message.success(result.message);
                    }
                }
                if (!result.isSuccess) {
                    message.error(result.message);
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        // danh sách đăng ký giao hàng
        getListDeliveryRegistration: async (store, data) => {
            try {
                const result = await purchaseOrderServices.getListDeliveryRegistration(data);
                runInAction(() => {
                    store.deliveryRegistrationRes = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // thực thi transition button
        executeTransitionButton: async (data) => {
            try {
                const result = await purchaseOrderServices.executeTransitionButton(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        // hủy phiếu 
        cancelDeliveryRegistration: async (data) => {
            try {
                const result = await purchaseOrderServices.cancelDeliveryRegistration(data);
                if (result.isSuccess) {
                    message.success(result.message);
                } else {
                    message.error(result.message);
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        // export excel màn danh sách PO
        exportListPO: async (request) => {
            try {
                await purchaseOrderServices.exportListPO(request);
            } catch (error) {

            }
        },
        exportCreatePOTemplate: async () => {
            try {
                await purchaseOrderServices.exportCreatePOTemplate();
            } catch (error) {

            }
        },
        // Cập nhật PO item
        updatePOItem: async (request) => {
            try {
                const result = await purchaseOrderServices.updatePOItem(request);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.success(result.message)
                }
                return result.isSuccess;
            } catch (error) {
                return false
            }
        },
        // Lịch sử chỉnh sửa 
        getHistory: async (store, data) => {
            try {
                const result = await purchaseOrderServices.getHistory(data);
                runInAction(() => {
                    store.dataHistory = result;
                });
            } catch (error) {
            }
        },

        // --- ĐỒNG BỘ PO ----------------------------------------------------------------------------------------------------------------------------------
        // search PO SAP
        searchPOSAP: async (store, data) => {
            try {
                const result = await purchaseOrderServices.searchPOSAP(data);
                runInAction(() => {
                    store.searchSynchPO = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // Đồng bộ po
        synchPO: async (data) => {
            try {
                const result = await purchaseOrderServices.synchPO(data);
                return result
            } catch (error) {
                console.log(error);
                return {
                    data: null,
                    isSuccess: false,
                    message: ''
                }
            }
        }, // Tạo mới PO
        createPurchaseOrder: async (data) => {
            try {
                const result = await purchaseOrderServices.createPurchaseOrder(data);
                return result
            } catch (error) {
                console.log(error);
                return {
                    data: null,
                    isSuccess: false,
                    message: ''
                }
            }
        },
        // Cập nhật PO
        updatePurchaseOrder: async (data) => {
            try {
                const result = await purchaseOrderServices.updatePurchaseOrder(data);
                return result
            } catch (error) {
                console.log(error);
                return {
                    data: null,
                    isSuccess: false,
                    message: ''
                }
            }
        },
        // Xóa PO
        deletePurchaseOrder: async (id) => {
            try {
                const result = await purchaseOrderServices.deletePurchaseOrder(id);
                return result
            } catch (error) {
                console.log(error);
                return {
                    data: null,
                    isSuccess: false,
                    message: ''
                }
            }
        },
        // Chi tiết PO
        getDetailsPurchaseOrder: async (store, id) => {
            try {
                const result = await purchaseOrderServices.getDetailsPurchaseOrder(id);
                runInAction(() => {
                    store.detailPO = result.data;
                });
            } catch (error) {
                console.log(error);

            }
        },
    };
}
