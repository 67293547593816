import React, { Dispatch, SetStateAction } from 'react'
import { CardBox } from './cardBox';
import { SearchItem } from 'pages/Components/SearchBox';
import { Checkbox, DatePicker, Form, FormInstance, Input, InputNumber, TimePicker } from 'antd';
import { sFormatNumber } from 'utils/formatHelper';
import dayjs, { Dayjs } from 'dayjs';
import { PurchaseOrderModel } from 'models/PurchaseOrder';

type GeneralInformationProps = {
    disabledField: () => boolean;
    deliveryRegistrationId?: string;
    role: string;
    accessUpdated: boolean;
    formCreate: FormInstance<any>;
    totalDeliveredQty: { total: number; itemsTotal: number }
    dataFromList?: PurchaseOrderModel[],
    setTotalDeliveryQty: Dispatch<SetStateAction<{
        total: number;
        itemsTotal: number;
    }>>
}

const GeneralInformation: React.FC<GeneralInformationProps> = (props) => {
    const {
        accessUpdated,
        disabledField,
        role,
        deliveryRegistrationId,
        formCreate,
        totalDeliveredQty,
        dataFromList,
        setTotalDeliveryQty
    } = props
    return (
        <CardBox title="Thông tin chung" >
            <div >
                {/* gửi mail */}
                <SearchItem initialValue={false} name={'isSendEmail'} valuePropName="checked" >
                    <Checkbox disabled={disabledField()}>Hệ thống tự động gửi mail cho nhà cung cấp</Checkbox>
                </SearchItem>
                {/* Mã nhà cung cấp */}
                <SearchItem name='vendorNumber' hidden >
                    <Input />
                </SearchItem>
                {/* nhà cung cấp */}
                <SearchItem name={'vendorName'} label='Nhà cung cấp'>
                    <Input disabled />
                </SearchItem>
                {/* Tình trạng phiếu */}
                {deliveryRegistrationId && <SearchItem name={'taskStatusName'} label='Tình trạng phiếu'>
                    <Input disabled />
                </SearchItem>}
                {/* Thời gian giao hàng */}
                <SearchItem required={true} label='Thời gian giao hàng'>
                    <Form.List name='deliveryTimeItems' rules={[{
                        validator: async (_, value) => {
                            if (value && value.length) {
                                const total = value.reduce(
                                    (sum: number, item: { time?: Dayjs; deliveryQuantity?: number }) => sum + (item?.deliveryQuantity || 0),
                                    0
                                );
                                if (total > totalDeliveredQty.total) {
                                    return Promise.reject(new Error('Tổng số lượng hàng giao theo từng đợt không khớp với tổng số lượng đã đăng ký.'));
                                }
                            }
                        },
                    }]}>
                        {(fiels, { add, remove }, { errors }) => <div className="flex flex-col gap-3">
                            {fiels.map((fiel, index) => (
                                <div key={index} className="w-full flex gap-default items-start">
                                    {/* thời gian giao */}
                                    <SearchItem hidden name={[fiel.name, 'deliveryTime']}>
                                        <Input />
                                    </SearchItem>
                                    {/* id đợt giao hàng */}
                                    <SearchItem hidden name={[fiel.name, 'deliveryRegistrationTimeId']}>
                                        <Input />
                                    </SearchItem>
                                    <div className="flex w-full">
                                        {/* hiện ngày giao hàng */}
                                        <SearchItem name={[fiel.name, 'day']} noStyle rootClassName="!w-28">
                                            <DatePicker style={{ width: '100%' }} format={'DD/MM/YYYY'}
                                                // PP được phép chỉnh sửa ngày giao hàng
                                                disabled={!(role === "PP" && accessUpdated)}
                                                allowClear={false}
                                                onChange={(value) => {
                                                    formCreate.setFieldValue(['deliveryTimeItems', fiel.name, 'deliveryTime'],
                                                        `${value?.format("YYYY-MM-DD")}T${dayjs(formCreate.getFieldValue(['deliveryTimeItems', fiel.name, 'time']))?.format("HH")}:00:00`)
                                                }}
                                                className="!rounded-r-none" />
                                        </SearchItem>
                                        {/* giờ giao hàng */}
                                        <SearchItem
                                            name={[fiel.name, 'time']}
                                            rules={[{ required: true, message: 'Vui lòng chọn "Thời gian giao hàng".' }]}
                                            rootClassName="flex-1"
                                        >
                                            <TimePicker
                                                disabled={disabledField()}
                                                onChange={(value) => {
                                                    formCreate.setFieldValue(['deliveryTimeItems', fiel.name, 'deliveryTime'],
                                                        `${!deliveryRegistrationId ?
                                                            dayjs(dataFromList?.at(0)?.documentDate).format("YYYY-MM-DD") :
                                                            dayjs(formCreate.getFieldValue(['deliveryTimeItems', fiel.name, 'deliveryTime'])).format('YYYY-MM-DD')
                                                        }T${value?.format("HH")}:00:00`)
                                                }}
                                                changeOnBlur
                                                className="w-full !rounded-l-none" format={"hh A"}
                                                placeholder="hh:mm A"

                                            />
                                        </SearchItem >
                                    </div>
                                    {fiels.length > 1 && (
                                        <SearchItem
                                            name={[fiel.name, 'deliveryQuantity']}
                                            className="!w-full" rootClassName="w-1/3"
                                            rules={[{ required: true, message: "Vui lòng nhập số lượng." }, { type: 'number', min: 1, message: "Số lượng phải lớn hơn 0!" }]}
                                        >
                                            <InputNumber<number>
                                                formatter={(value) => {
                                                    return sFormatNumber(Number(value)) as string;
                                                }}
                                                disabled={disabledField()}
                                                onBlur={(e) => {
                                                    const currentValues = formCreate.getFieldValue('deliveryTimeItems');
                                                    const newValues = [...currentValues];
                                                    const total = newValues.reduce(
                                                        (sum: number, item: { time?: typeof dayjs; deliveryQuantity?: number }) => sum + (item?.deliveryQuantity || 0),
                                                        0
                                                    );
                                                    setTotalDeliveryQty(prev => ({ ...prev, itemsTotal: total }))
                                                }}
                                                className="!w-full" placeholder="Số lượng giao hàng" />
                                        </SearchItem >
                                    )}
                                    {/* {index === 0 ? (
                                <Button
                                    disabled={disabledField()} type="link"
                                    icon={<TbCirclePlus
                                        className={`text-xl ${(() => {
                                            if (totalDeliveredQty.total > 0 && totalDeliveredQty.total > totalDeliveredQty.itemsTotal)
                                                if (deliveryRegistrationId) {
                                                    if (!disabledField())
                                                        return "cursor-pointer text-teal-500 "
                                                } else {
                                                    return "cursor-pointer text-teal-500 "
                                                }
                                            return "cursor-not-allowed text-gray-400 "
                                        })()}`}
                                    />}
                                    onClick={() => {
                                        if (totalDeliveredQty.total > 0 && totalDeliveredQty.total > totalDeliveredQty.itemsTotal) {
                                            if (deliveryRegistrationId) {
                                                const documentDate = dataDetail?.deliveryTimeItems?.at(0)?.deliveryTime
                                                add({ day: documentDate && dayjs(documentDate) })
                                            } else {
                                                const documentDate = dataFromList?.at(0)?.documentDate
                                                add({ day: documentDate && dayjs(documentDate) })
                                            }
                                        }
                                    }} >
                                </Button>) :
                                <Button type="link"
                                    disabled={disabledField()}
                                    icon={<TbCircleMinus
                                        className={`text-xl ${(() => {
                                            if (!disabledField())
                                                return "cursor-pointer text-red-500 "
                                            return "cursor-not-allowed text-gray-400 "
                                        })()}`}
                                    />}
                                    onClick={() => {
                                        const currentValues = formCreate.getFieldValue(['deliveryTimeItems', fiel.name, 'deliveryQuantity']);
                                        setTotalDeliveryQty(prev => ({ ...prev, itemsTotal: prev.itemsTotal - (Number(currentValues) || 0) }))
                                        remove(index)
                                    }} >
                                </Button>} */}
                                </div>
                            ))}
                            <Form.ErrorList errors={errors} />
                        </div>}
                    </Form.List>
                </SearchItem>
            </div>
        </CardBox>
    )
}

export default GeneralInformation