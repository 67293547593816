import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const reportRoutes = ({
    RegistrationList, DeliveryResult, DeliveryRate, ReceivingSchedule
}: {
    // Danh sách xe đăng ký giao hàng
    RegistrationList: React.ComponentType;
    // Báo cáo kết quả giao hàng
    DeliveryResult: React.ComponentType;
    // Báo cáo tỷ lệ giao hàng đúng hẹn
    DeliveryRate: React.ComponentType;
    // Receiving Schedule of RHF
    ReceivingSchedule: React.ComponentType;
}) => (
    <Routes>
        {/* Báo cáo danh sách đăng ký xe giao hàng */}
        <Route path={ROUTES.REPORT.DELIVERY_REGISTRATION.BASE} element={<RegistrationList />} />
        {/* Báo cáo kết quả giao hàng */}
        <Route path={ROUTES.REPORT.DELIVERY_RESULT.BASE} element={<DeliveryResult />} />
        {/* Báo cáo tỷ lệ giao hàng thành công */}
        <Route path={ROUTES.REPORT.DELIVERY_RATE.BASE} element={<DeliveryRate />} />
        {/* Receiving schedule of BHF */}
        <Route path={ROUTES.REPORT.RECEIVING_SCHEDULE.BASE} element={<ReceivingSchedule />} />
    </Routes>
);
