import * as Icon from 'base/ui/components/Icons';

import { Form, Input, Modal, Select, Tooltip, Upload, message } from 'antd';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    driverPresenter,
    userStore,
    driverStore,
    commonPresenter,
    commonStore,
} from 'stores/root_store';
import { BASE_API_URL, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-components';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomSelect from 'base/ui/components/CustomSelect';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { HiSearch } from 'react-icons/hi';
import { DataSearchDriver } from '../model';
import { cloneDeep } from 'lodash';
import { SearchModel } from 'pages/model';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { statusList } from 'pages/rawData/Permission/model';
import { usePermission } from 'hooks/usePermission';
import { Driver } from 'services/RawData/driver_services';
import { CommonModel } from 'services/Main/common_services';
import { debounce } from 'utils/debounce';
import { removeEmptyValues } from 'utils/transformHelper';
import { ExportButton } from 'pages/Components/FunctionButton';
import { getAccessToken } from 'utils/localStorageHelper';
import { checkPermissionWithId } from 'utils/permissionHelper';

const initSearch = {
    paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
    },
    trainingResult: 'DAT',
    actived: true
}

const { confirm } = Modal;
// Nhà cung cấp
const driverPage = () => {
    return observer(() => {
        const { t } = useTranslation();
        usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

        const isExport = checkPermissionWithId('EXPORT_TEMPLATE')
        const isImport = checkPermissionWithId('IMPORT')

        const pagetitle = "Tài xế";
        document.title = pagetitle + ' - ' + SYSTEM_TITLE;
        const navigate = useNavigate();
        const [form] = Form.useForm();
        // state ===============================================================================
        const [openDrawer, setOpenDrawer] = useState(false);
        // Columns table title
        const defaultColumn: ProColumns<Driver>[] = [
            // Stt
            {
                title: "STT",
                dataIndex: 'stt',
                key: 'stt',
                width: 50,
                align: 'center',
                render: (text) => <div className="!text-center">{text}</div>,
                fixed: 'left',
            },
            // Nhà cung cấp
            {
                title: "Nhà cung cấp",
                dataIndex: 'vendorFmt',
                key: 'vendorFmt',
                width: 250,
                render: (_, record) => {
                    return `${record.vendorNumber} | ${record.vendorName}`
                },
            },
            // Mã tài xế
            {
                title: "Mã tài xế",
                dataIndex: 'driverCode',
                key: 'driverCode',
                width: 100,
                render: (_, record) => {
                    return userStore.userPermission?.includes('EDIT') ? (
                        <Link to={`/MasterData/Driver/${record.driverId}`}>{record.driverCode}</Link>
                    ) : (
                        <div>{record.driverCode}</div>
                    );
                },
            },
            // Tên tài xế
            {
                title: "Tên tài xế",
                dataIndex: 'driverName',
                key: 'driverName',
                width: 150,
                ellipsis: true,
            },

            // Điện thoại
            {
                title: "Số điện thoại",
                dataIndex: 'phone',
                key: 'phone',
                width: 100,
            },
            // Số bằng lái
            {
                title: "Số bằng lái",
                dataIndex: 'licenseNumber',
                key: 'licenseNumber',
                width: 100,
            },
            //   Kết quả đào tạo
            {
                title: 'Kết quả đào tạo an toàn',
                dataIndex: 'trainingResultName',
                width: 120,
                key: 'trainingResultName',
            },
            // trạng thái
            {
                title: t('permission.account.status'),
                dataIndex: 'actived',
                width: 80,
                key: 'actived',
                align: 'center',
                render: (_, record) => (
                    <div className="w-full flex justify-center">
                        {record.actived ? (
                            <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
                        ) : (
                            <Icon.CloseStatus width="15" className="!fill-red-500" />
                        )}
                    </div>
                ),
            },
            {
                title: t('permission.account.actions'),
                dataIndex: 'functions',
                key: 'functions',
                width: 80,
                fixed: 'right',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div className="w-full flex items-center justify-center gap-x-1">
                            {userStore.userPermission?.includes('EDIT') ? (
                                <Tooltip title={t('btn.edit')}>
                                    <Link to={record.driverId}>
                                        <CustomButtonIcon color="#007BF1">
                                            <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                                        </CustomButtonIcon>
                                    </Link>
                                </Tooltip>
                            ) : null}
                            {userStore.userPermission?.includes('DELETE') && record.actived ? (
                                <Tooltip title={t('btn.delete')}>
                                    <CustomButtonIcon
                                        color="#FC5C5E"
                                        onClick={() => {
                                            showDeleteConfirm(record);
                                        }}
                                    >
                                        <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                                    </CustomButtonIcon>
                                </Tooltip>
                            ) : <span className='w-[25px] h-[25px] bg-transparent'></span>}
                        </div>
                    );
                },
            },

        ];

        // Custom default columns table with resizeable
        const [columns, setColumn] = useState<ProColumns<Driver>[]>(defaultColumn);
        // Loading all page
        const [loading, setLoading] = useState(false);
        const [listVendor, setListVendor] = useState<CommonModel[]>([])
        const [loadingExport, setLoadingExport] = useState(false);
        const [loadingImport, setLoadingImport] = useState(false);

        const tableRef = useRef<ActionType>();

        // ====================SEARCH DROPDOWN=========================================

        // Data search
        const [dataSearch, setDataSearch] = useState<SearchModel>(initSearch);

        // List data table
        const [listDriver, setListDriver] = useState<Driver[]>([]);

        // Function open hide drawer
        const showDrawer = () => {
            setOpenDrawer(true);
        };

        const onCloseDrawer = () => {
            setOpenDrawer(false);
        };

        // Call api search data
        const getDataSearchDriver = async (dataSearch: DataSearchDriver) => {
            await driverPresenter.searchDriver(driverStore, dataSearch);
            if (driverStore.searchDriverResponse?.isSuccess) {
                setListDriver(driverStore.searchDriverResponse?.data);
                return driverStore.searchDriverResponse.data
            }
            return []
        };

        // Handle click button search
        const handleSearch = (values: any) => {
            setLoading(true);
            const dataResult = cloneDeep(values);
            // loại bỏ những field không có giá trị
            const search = removeEmptyValues(dataResult)
            // set pageIndex 1 when search form
            setDataSearch({
                ...search,
                paging: {
                    pageSize: 10,
                    pageIndex: 1,
                },
            });
            onCloseDrawer();
            setLoading(false);
        };

        // Ui modal confirm delete store
        const showDeleteConfirm = (item: Driver) => {
            confirm({
                title: `Xóa tài xế`,
                icon: <ExclamationCircleFilled />,
                content: `${t('message.question-delete')} tài xế "${item.driverName}"?`,
                okText: t('btn.delete'),
                okType: 'danger',
                cancelText: t('btn.cancel'),
                className: 'custom__modalconfirm--delete',
                onOk() {
                    handleClickDelete(item.driverId);
                },
                onCancel() { },
            });
        };

        // Call api delete
        const handleClickDelete = async (driverId: string) => {
            setLoading(true);
            if (driverId) {
                await driverPresenter.deleteDriver(driverStore, driverId);
                if (driverStore.crudResponse.isSuccess) {
                    message.success(driverStore.crudResponse.message);
                    // Call list branch after change
                    setDataSearch(initSearch)
                    tableRef.current?.reload()
                } else {
                    message.error(driverStore.crudResponse.message);
                }
            }
            setLoading(false);
        };

        // Dropdown nhà cung cấp
        const getDropdownVendor = async (keyword?: string) => {
            await commonPresenter.getDropdownVendor(commonStore, { keyword: keyword, takeNumber: 20 });
            if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
                setListVendor(commonStore.commonRespone.data)
            }
        }
        const searchVendor = useCallback(debounce(getDropdownVendor, 300), [])

        // danh sách kết quả đào tạo
        const getTrainingResult = async () => {
            if (!commonStore.dropdownTrainingResult.length) {
                await commonPresenter.getDropdownTrainingResult(commonStore)
            }
        }

        // ================================ CALL DATA ==========================================
        // scroll to top
        useEffect(() => {
            getDropdownVendor()
            getTrainingResult()
            window.scrollTo(0, 0);
        }, []);

        return (
            <div className="w-full">
                {/* ================ Breadcrumb =============== */}
                <CustomBreadcrumb
                    pagetitle={[{ title: pagetitle, link: '/Masterdata/SalesEmployee' }]}
                    extra={
                        <>
                            <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                                <Icon.Filter className="fill-primary" />
                            </CustomButton>
                            {isImport && <Upload
                                maxCount={1}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                showUploadList={false}
                                onChange={async (info) => {
                                    if (info.file.status === "uploading") {
                                        setLoadingImport(true)
                                    }
                                    if (info.file.status === 'done') {
                                        setLoadingImport(false)
                                        message.success(info.file.response.message);
                                        setDataSearch(initSearch)
                                        tableRef.current?.reload()
                                    }
                                    if (info.file.status === 'error') {
                                        message.error(info.file.response.message);
                                    }
                                }}
                                action={BASE_API_URL + '/driver/import-driver'}
                                headers={{
                                    Authorization: `Bearer ${getAccessToken()}`,
                                }}
                                name="File"
                            >
                                <CustomButton
                                    loading={loadingImport}
                                    icon={<Icon.Upload />}>
                                    Import
                                </CustomButton>
                            </Upload>}
                            {isExport && <ExportButton
                                title='Export template'
                                loading={loadingExport}
                                onClick={async () => {
                                    setLoadingExport(true)
                                    await driverPresenter.exportCreateDriverTemplate()
                                    setLoadingExport(false)
                                }}
                            />}
                            {userStore.userPermission?.includes('CREATE') ? (
                                <CustomButton
                                    onClick={() => {
                                        navigate('create');
                                    }}
                                    type="primary"
                                    icon={<Icon.Plus className="!fill-[#fff]" />}
                                >
                                    {t('btn.add')}
                                </CustomButton>
                            ) : null}
                        </>
                    }
                />
                {/* Table */}
                <BoxContainer>
                    <CustomProTable<Driver>
                        columns={columns}
                        rowKey="stt"
                        size="small"
                        bordered
                        actionRef={tableRef}
                        search={false}
                        loading={loading}
                        request={async (params = {}, sort, filter) => {
                            setLoading(true);
                            const data: any = await getDataSearchDriver({
                                ...dataSearch,
                                paging: {
                                    ...dataSearch.paging,
                                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                },
                            });

                            if (Object.values(sort).length) {
                                setDataSearch({
                                    ...dataSearch,
                                    paging: {
                                        ...dataSearch.paging,
                                        orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                        orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                    },
                                });
                            }
                            setLoading(false);
                            return {
                                data: data,
                                success: driverStore.searchDriverResponse?.isSuccess,
                            };
                        }}
                        dataSource={listDriver}
                        pagination={{
                            ...tablePagination(dataSearch, setDataSearch),
                            total: driverStore.searchDriverResponse?.paging?.recordsTotal
                        }}

                        options={false}
                    />
                </BoxContainer>

                <SearchBox // tìm kiếm
                    className="lg:!w-[50%]"
                    footer={[
                        <div key="footer" className="flex justify-end">
                            <CustomButton
                                type="primary"
                                icon={<HiSearch />}
                                htmlType="submit"
                                onClick={() => {
                                    form.submit();
                                    onCloseDrawer();
                                }}
                            >
                                Tìm kiếm
                            </CustomButton>
                        </div>,
                    ]}
                    onClose={onCloseDrawer}
                    open={openDrawer}
                >
                    <SearchForm
                        form={form}
                        onFinish={(values) => {
                            handleSearch(values);
                            tableRef.current?.reload();
                        }}
                        layout="vertical"
                        column="col-2"
                    >
                        <button type='submit' hidden></button>
                        {/* Nhà cung cấp */}
                        <SearchItem label={'Nhà cung cấp'} name={'vendorNumber'}>
                            <Select
                                allowClear
                                options={listVendor.map(v => ({ value: v.key, label: v.value }))}
                                filterOption={false}
                                popupMatchSelectWidth={false}
                                showSearch onSearch={(values) => searchVendor(values || undefined)}
                            />
                        </SearchItem>
                        {/* Mã tài xế */}
                        <SearchItem label={"Mã tài xế"} name={'driverCode'}>
                            <Input />
                        </SearchItem>
                        {/* Số điện thoại */}
                        <SearchItem label={"Số điện thoại"} name={'phone'}>
                            <Input />
                        </SearchItem>
                        {/* Tên tài xế */}
                        <SearchItem label={"Tên tài xế"} name={'driverName'}>
                            <Input />
                        </SearchItem>
                        {/* Số bằng lái */}
                        <SearchItem label={"Số bằng lái"} name={'licenseNumber'}>
                            <Input />
                        </SearchItem>
                        {/* Kết quả đào tạo an toàn */}
                        <SearchItem initialValue={'DAT'} label={'Kết quả đào tạo an toàn'} name={'trainingResult'}>
                            <Select options={commonStore.dropdownTrainingResult} fieldNames={{ label: "catalogName", value: 'catalogCode' }} allowClear placeholder="-- Tất cả --" />
                        </SearchItem>
                        {/* Trạng thái */}
                        <SearchItem initialValue={true} label={'Trạng thái'} name={'actived'}>
                            <CustomSelect options={statusList} className="!rounded-none" allowClear placeholder="-- Tất cả --" />
                        </SearchItem>
                    </SearchForm>
                </SearchBox>
            </div>
        );
    });
};

export default driverPage;
