import { BASE_API_AUTH } from 'utils/constants';
import { get, post } from '../../utils/api';
import { DataResponse } from '../response';

// ===================== User Model =============================//
// module
export type ModuleModel = {
  moduleId: string;
  moduleName: string;
  icon: string;
  isSystemModule: boolean;
  orderIndex: number;
  imageUrl: string;
  description: string;
  details: string;
  url: string;
  rowIndex: number;
  error: any;
  isNullValueId: boolean;
  menuId: string;
  menuName: string;
  menuModel: MenuModel2[]
  activedMenuList: any;
  pageList: any;
  activedPageList: any;
  pageId: string;
  pageName: string;
}

export interface MenuModel2 {
  menuId: string
  menuName: string
  moduleId: string
  icon: string
  orderIndex: number
  pageViewModels: PageViewModel[]
  rowIndex: number
  error: any
  isNullValueId: boolean
  isChooseAll: any
}

export interface PageViewModel {
  pageId: string
  pageName: string
  pageUrl: string
  icon: any
  parameter?: string
  moduleId: string
  menuId: string
  orderIndex: number
  isChooseAll: any
  domainConfig: any
  domainConfigUrl?: string
}

// Menu con // submenu
export type ChildMenu = {
  menuId: string;
  icon: string;
  pageName: string;
  pageId: string;
  pageUrl: string;
  domainConfigUrl: string;
  domainConfig: number;
  parameter: string;
};

export type Menu = {
  menuId: string;
  icon: string;
  menuName: string;
};
export interface PagePermissionModel {
  pageId: string;
  functionId: string;
}
export type WebPermission = {
  menuModel: Menu[];
  pageModel: ChildMenu[];
  pagePermissionModel: PagePermissionModel[];
  moduleModel: ModuleModel[]
};


// user
export type User = {
  accountId: string;
  token: string;
  userName: string;
  employeeCode: string;
  fullName: string;
  validaty: string;
  refreshToken: string;
  companyId: string;
  role: string;
  roleName: string;
  roles: string;
  expiredTime: Date;
  webPermission: WebPermission;
  plantCode: string;
  plantName: string;
  saleOrgCode: string;
  saleOrgName: string;
  isShowDashboard: boolean;
  isShowChooseModule: boolean;

  qrCode: string;
  isAuthenticate: boolean;
};

export interface KeyAndValue {
  key: string;
  value: string
}
export interface CompanyByUsername {
  isLogAuthen: boolean;
  listCompany: KeyAndValue[]
}
// interface của user service
// Khai báo phương thức
export interface IUserServices {
  // Đăng nhập
  login(data: any): Promise<DataResponse<User>>;
  // danh sách company by username
  getCompanyByUsername(username: string): Promise<DataResponse<CompanyByUsername>>
  // danh sách saleorg by username
  getSaleorgByUsername({ companyCode, username }: { username: string; companyCode: string }): Promise<DataResponse<KeyAndValue[]>>
  // Xác thực OTP
  confirmOTP(data: { userName: string; otp: string, saleOrg: string }): Promise<DataResponse<User>>;
}
// Hàm khởi tạo các phươn thức của user service
export function createUserServices(): IUserServices {
  return {
    login: async (data: any): Promise<DataResponse<User>> => {
      return (await post(BASE_API_AUTH, `auth/login`, data)).data;
    },
    // danh sách company by username
    getCompanyByUsername: async (username) => {
      return (await get(BASE_API_AUTH, `auth/get-company-by-username/${username}`)).data;
    },
    // danh sách saleorg by username
    getSaleorgByUsername: async ({ username, companyCode }) => {
      return (await get(BASE_API_AUTH, `auth/get-saleorg-by-username/${username}?companyCode=${companyCode}`)).data;
    },
    // Xác thực OTP
    confirmOTP: async (data) => {
      return (await post(BASE_API_AUTH, `auth/confirm-otp`, data)).data;
    }
  };
}
