import { userPresenter, userStore } from "stores/root_store";

export const getAccessToken = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.token : null;
};

export const getExpiredTime = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.expiredTime : null;
};

export const getRoles = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.roles : null;
};
export const getRole = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.role : null;
};

export const getRoleName = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.roleName : null;
};

export const getPlantCode = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.plantCode : null;
};
export const getPlantName = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.plantName : null;
};
export const getCompanyId = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.companyId : null;
};

export const getAccountId = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.accountId : null;
};

export const getsaleOrg = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.saleOrgCode : null;
};
export const getsaleOrgName = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.saleOrgName : null;
};

export const getRefreshToken = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.refreshToken : null;
};

export const getUserName = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.userName : null;
};

export const getUserSession = () => {
  return JSON.parse(localStorage.getItem('user_session') || '{}');
};

export const getFormCompleted = () => {
  return JSON.parse(localStorage.getItem('form_complete') || '[]') as string[];
}

export const getIsShowDashboard = (): boolean => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.isShowDashboard : false;
};

export const getIsShowChooseModule = (): boolean => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.isShowChooseModule : false;
};

export const setFormCompleted = (arr: string[]) => {
  // return JSON.parse(localStorage.getItem('form_complete') || '[]')
  localStorage.setItem('form_complete', JSON.stringify(arr));
}


export const setUserSession = (userSession: any) => {
  localStorage.setItem('user_session', JSON.stringify(userSession) || '{}');
};

export const setToken = (accessToken: any) => {
  // console.log(accessToken);
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  userSession.token = accessToken.token;
  userSession.refreshToken = accessToken.refreshToken;
  localStorage.setItem('user_session', JSON.stringify(userSession));
};



export const clearUserSession = () => {
  localStorage.removeItem('user_session');
  localStorage.removeItem('logoutTime');
  userPresenter.setCurrentUser(userStore, null);
};
