import { Avatar, Divider, Dropdown, MenuProps, Space, theme } from 'antd';
import { ArrowRightFromCircle, ChevronDown, CogOutline } from 'base/ui/components/Icons';
import { observer } from 'mobx-react';
import React from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { uiStore, userPresenter } from 'stores/root_store';
import { MVC_URL } from 'utils/constants';

const { useToken } = theme;

// xử lý đăng xuất
const handleSignOut = async () => {
  await userPresenter.signOut();
  // navigate('/logout');
  window.location.reload();
};

const items: MenuProps['items'] = [
  {
    label: (
      <Link
        to={'/ChangePassword'}
        className={'flex items-center gap-default px-[10px] py-[5px]'}
      >
        <CogOutline className="fill-gray-500" />
        <span className="text-default leading-[22px] font-medium text-dark">Đổi mật khẩu</span>
      </Link>
    ),
    key: '1',
  },
  {
    label: (
      <span onClick={handleSignOut} className={'flex items-center gap-default px-[10px] py-[5px]'}>
        <ArrowRightFromCircle className="fill-gray-500" />
        <span onClick={handleSignOut} className="text-default leading-[22px] font-medium text-dark">
          Đăng xuất
        </span>
      </span>
    ),
    key: '2',
  },
];

function AcountSetting() {
  const { token } = useToken();

  const data = JSON.parse(localStorage.getItem('user_session') || '{}');

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: '3px',
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
  };

  const menuStyle = {
    boxShadow: 'none',
    padding: 0,
  };


  function hashCode(str: string) {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  function intToRGB(i: number) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }


  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          <div className="min-h-[165px] min-w-[240px] flex flex-col justify-center items-center">
            <Avatar style={{ backgroundColor: "#" + intToRGB(hashCode(data?.userName)), verticalAlign: 'middle' }} size={68}>
              {data.userName ? data.userName[0].toUpperCase() : ''}
            </Avatar>
            <span className='mt-2'>{data.fullName}</span>
          </div>
          <Divider style={{ margin: 0 }} />
          {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
        </div>
      )}
    >
      <Space className="cursor-pointer">
        <div className="flex items-center">
          <div className="h-[48px] flex items-center gap-[6px] text-white cursor-pointer">
            <Avatar style={{ backgroundColor: "#" + intToRGB(hashCode(data?.userName)), verticalAlign: 'middle' }} size={32}>
              {data.userName ? data.userName[0].toUpperCase() : ''}
            </Avatar>
            <span
              className={`hidden lg:block font-[400] text-sm select-none ${uiStore.theme.name === 'gray' ? 'text-white' : 'text-dark'
                }`}
            >
              {data.userName}
            </span>
            <span className="hidden lg:block">
              <ChevronDown className={`${uiStore.theme.name === 'gray' ? '!fill-[#D1E8FF]' : '!fill-dark'}`} />
            </span>
          </div>
        </div>
      </Space>
    </Dropdown>
  );
}

export default memo(observer(AcountSetting));
