import React, { useCallback, useImperativeHandle, useState } from 'react';
import { Checkbox, Form, Input, Modal, message } from 'antd';
// import { TypeColumnsBase } from './model';
import { reportSavedPresenter, reportSavedStore } from 'stores/root_store';
import { TypeColumnsBase } from 'pages/model';

const TemplateReportModal = React.forwardRef((props: any, ref) => {
  // =========================STATE=========================
  // Modal add report
  const [openModalAddDocument, setOpenModalAddDocument] = useState({
    visible: false,
    type: '',
    title: '',
  });
  // Modal update report
  const [openModalUpdateDocument, setOpenModalUpdateDocument] = useState({
    visible: false,
    type: '',
    title: '',
    reportId: '',
  });
  // =======================FUCTION=======================

  // Function handle show modal report create
  const showModal = (reportType: string) => {
    // Set title modal form
    let title = '';
    if (reportType === 'system') {
      title = 'Thêm mới báo cáo hệ thống';
    } else if (reportType === 'user') {
      title = 'Thêm mới báo cáo của bạn';
    }

    setOpenModalAddDocument({
      visible: true,
      type: reportType,
      title: title,
    });
  };

  // Function handle show modal report update
  const showModalUpdate = (reportType: string, reportId: string) => {
    // Set title modal form
    let titleUpdate = '';
    if (reportType === 'system') {
      titleUpdate = 'Cập nhật báo cáo hệ thống';
    } else if (reportType === 'user') {
      titleUpdate = 'Cập nhật báo cáo của bạn';
    }

    // Check report click by from system or user
    const reports = reportType === 'system' ? props.systemReports : props.userReports;
    const selectedReport = reports.find((report: TypeColumnsBase) => report.id === reportId);

    // Set values for fields in the form when the form is opened.
    props.form.setFieldsValue({
      title: selectedReport?.title,
      description: selectedReport?.description,
      default: selectedReport?.default,
    });

    if (selectedReport) {
      setOpenModalUpdateDocument({
        visible: true,
        type: reportType,
        title: titleUpdate,
        reportId: reportId,
      });
    }
  };

  // Function handle cancel save form modal
  const handleCancel = () => {
    // Reset state add
    setOpenModalAddDocument({
      visible: false,
      type: '',
      title: '',
    });
    // Reset state update
    setOpenModalUpdateDocument({
      visible: false,
      type: '',
      title: '',
      reportId: '',
    });
    // Reset form if click cancel modal
    props.form.resetFields();
  };

  // Function handle get item report
  const handleGetReportSaved = useCallback(
    (reportType: string, reportId: string) => {
      // Set id report selected
      props.setSelectedReportId(reportId);
      let presentTemp: any;
      if (reportType === 'system') {
        presentTemp = props.systemReports.find((i: TypeColumnsBase) => i.id === reportId);
      } else if (reportType === 'user') {
        presentTemp = props.userReports.find((i: TypeColumnsBase) => i.id === reportId);
      }

      // Check data present
      if (presentTemp) {
        props.setColumnsState(presentTemp.value);

        if (presentTemp.resizable) {
          props.setResizableColumnSelect(presentTemp.resizable);
          props.setIsResizablePresent(true);
        }
      } else {
        message.error('Báo cáo không tồn tại');
      }
    },
    [props.systemReports, props.userReports],
  );

  // Function handle save data report form modal
  const handleSaveReport = () => {
    // props.setIsLoading(true);
    props.form
      .validateFields()
      .then(async (values: TypeColumnsBase) => {
        if (Object.keys(props.columnsState).length > 0 || props.resizableColumns.length > 0) {
          // New data from form item
          // const newData: any = {
          //   id: uuid(),
          //   value: props.columnsState,
          //   title: values.title,
          //   description: values.description,
          //   default: values.default || false,
          //   resizable: props.resizableColumns,
          // };

          await reportSavedPresenter.saveReportTemplate(reportSavedStore, {
            reportName: props.constDataName,
            reportTemplateName: values.title,
            description: values.description,
            templateString: JSON.stringify(props.columnsState),
            isDefault: values.default || false,
            resizable: JSON.stringify(props.resizableColumns),
          });
          if (reportSavedStore.saveResponse.isSuccess) {
            message.success(reportSavedStore.saveResponse.message);
            await reportSavedPresenter.getListReportTemplate(reportSavedStore, props.constDataName);
            if (reportSavedStore.listTemplateResponse.isSuccess) {
              const newData = reportSavedStore.listTemplateResponse.data.map((item) => ({
                id: item.reportTemplateId,
                value: JSON.parse(item.templateString),
                title: item.reportTemplateName,
                description: item.description,
                default: item.isDefault,
                resizable: JSON.parse(item.resizable),
              }));
              props.setUserReports(newData);
            }
          } else {
            message.error(reportSavedStore.saveResponse.message);
          }

          // // Get data from localstorage if existing
          // const existingData = localStorage.getItem(props.constDataName);
          // if (existingData) {
          //   // Parse data from JSON
          //   const reports = JSON.parse(existingData);
          //   let checkTitle;
          //   let checkDefaultReport;
          //   // Check if user is adding "system" or "user" section
          //   if (openModalAddDocument.type === 'system') {
          //     // Check field default === true reset default old
          //     if (newData.default) {
          //       checkDefaultReport = reports.system.find((i: TypeColumnsBase) => i.default === true);
          //       if (checkDefaultReport) {
          //         checkDefaultReport.default = false;
          //       }
          //     }

          //     // Check field title same
          //     checkTitle = props.systemReports.find((i: any) => i.title === newData.title);
          //     if (checkTitle) {
          //       message.error('Tên mẫu báo cáo đã tồn tại');
          //     } else {
          //       // Add report to array "system"
          //       reports.system.push(newData);
          //       // Set list state report system after add new
          //       props.setSystemReports(reports.system);

          //       message.success(`Thêm mới báo cáo ${newData.title} thành công`);
          //     }
          //   } else {
          //     // Check field default === true reset default old
          //     if (newData.default) {
          //       checkDefaultReport = reports.user.find((i: TypeColumnsBase) => i.default === true);
          //       if (checkDefaultReport) {
          //         checkDefaultReport.default = false;
          //       }
          //     }

          //     // Check field title same
          //     checkTitle = props.userReports.find((i: any) => i.title === newData.title);
          //     if (checkTitle) {
          //       message.error('Tên mẫu báo cáo đã tồn tại');
          //     } else {
          //       // Add report to array "user"
          //       reports.user.push(newData);
          //       // Set list state report system after add new
          //       props.setUserReports(reports.user);

          //       message.success(`Thêm mới báo cáo ${newData.title} thành công`);
          //     }
          //   }
          //   // Save data orther to localStorage
          //   localStorage.setItem(props.constDataName, JSON.stringify(reports));

          // } else {
          //   let data: TypeColumnsState;
          //   if (openModalAddDocument.type === 'system') {
          //     // Create a new object containing both "system" and "user" arrays and save the new data to system
          //     data = {
          //       system: [newData],
          //       user: [],
          //     };
          //     message.success(`Thêm mới báo cáo ${newData.title} thành công`);
          //   } else {
          //     // Create a new object containing both arrays "system" and "user" and save the new data in user
          //     data = {
          //       system: [],
          //       user: [newData],
          //     };
          //     message.success(`Thêm mới báo cáo ${newData.title} thành công`);
          //   }
          //   // Set data list system and user
          //   props.setSystemReports(data.system);
          //   props.setUserReports(data.user);
          //   // Save data new to localStorage
          //   localStorage.setItem(props.constDataName, JSON.stringify(data));
          // }
          // Set close modal
          setOpenModalAddDocument({
            visible: false,
            type: '',
            title: '',
          });
        } else {
          message.error('Vui lòng cấu hình báo cáo');
        }
        props.form.resetFields();
      })
      .catch((info: any) => {
        message.error('Thêm mới báo cáo thất bại');
        console.log('Validate Failed:', info);
      });
    // props.setIsLoading(false);
  };

  // Function handle update item report
  const handleUpdateReport = () => {
    // props.setIsLoading(true);

    props.form
      .validateFields()
      .then(async (values: TypeColumnsBase) => {
        if (
          Object.keys(props.columnsState).length > 0 ||
          props.resizableColumnSelect.length > 0 ||
          props.resizableColumns.length > 0
        ) {
          // Item data update
          // const itemUpdate = {
          //   id: openModalUpdateDocument.reportId,
          //   title: values.title,
          //   value: props.columnsState,
          //   description: values.description,
          //   default: values.default,
          //   resizable: props.isResizablePresent ? props.resizableColumnSelect : props.resizableColumns,
          // };

          await reportSavedPresenter.updateReportTemplate(reportSavedStore, {
            reportTemplateId: openModalUpdateDocument.reportId,
            reportTemplateName: values.title,
            description: values.description,
            templateString: JSON.stringify(props.columnsState),
            isDefault: values.default,
            resizable: JSON.stringify(props.isResizablePresent ? props.resizableColumnSelect : props.resizableColumns),
          });
          if (reportSavedStore.updateResponse.isSuccess) {
            message.success(reportSavedStore.updateResponse.message);
            // lấy danh sách
            await reportSavedPresenter.getListReportTemplate(reportSavedStore, props.constDataName);
            if (reportSavedStore.listTemplateResponse.isSuccess) {
              const newData = reportSavedStore.listTemplateResponse.data.map((item) => ({
                id: item.reportTemplateId,
                value: JSON.parse(item.templateString),
                title: item.reportTemplateName,
                description: item.description,
                default: item.isDefault,
                resizable: JSON.parse(item.resizable),
              }));
              props.setUserReports(newData);
            }
          } else {
            message.error(reportSavedStore.updateResponse.message);
          }

          // Check data from localstorage
          // const existingData = localStorage.getItem(props.constDataName);
          // if (existingData) {
          //   const reports = JSON.parse(existingData);

          //   let checkDefaultReport;
          //   if (openModalUpdateDocument.type === 'system') {
          //     // Check field default === true reset default old
          //     if (itemUpdate.default) {
          //       checkDefaultReport = reports.system.find((i: TypeColumnsBase) => i.default === true);
          //       if (checkDefaultReport) {
          //         checkDefaultReport.default = false;
          //       }
          //     }
          //     // Check id report existing
          //     const reportIndex = reports.system.findIndex(
          //       (i: TypeColumnsBase) => i.id === openModalUpdateDocument.reportId,
          //     );
          //     if (reportIndex !== -1) {
          //       // Update the report in the system array
          //       reports.system[reportIndex] = itemUpdate;

          //       message.success(`Cập nhật báo cáo ${itemUpdate.title} thành công`);
          //     }
          //   } else if (openModalUpdateDocument.type === 'user') {
          //     // Check field default === true reset default old
          //     if (itemUpdate.default) {
          //       checkDefaultReport = reports.user.find((i: TypeColumnsBase) => i.default === true);
          //       if (checkDefaultReport) {
          //         checkDefaultReport.default = false;
          //       }
          //     }
          //     // Check id report existing
          //     const reportIndex = reports.user.findIndex(
          //       (i: TypeColumnsBase) => i.id === openModalUpdateDocument.reportId,
          //     );
          //     if (reportIndex !== -1) {
          //       // Update the report in the user array
          //       reports.user[reportIndex] = itemUpdate;
          //       message.success(`Cập nhật báo cáo ${itemUpdate.title} thành công`);
          //     }
          //   }
          //   // Save data update to localstorage
          //   localStorage.setItem(props.constDataName, JSON.stringify(reports));

          //   // Set value item report in dropdown
          //   props.setSystemReports(reports.system);
          //   props.setUserReports(reports.user);

          //   // Set modal close
          setOpenModalUpdateDocument({
            visible: false,
            type: '',
            title: '',
            reportId: '',
          });
          // } else {
          //   console.log('Data report not found');
          // }
        } else {
          message.error('Vui lòng cấu hình báo cáo để cập nhật');
        }
        props.form.resetFields();
      })
      .catch((info: any) => {
        message.error('Cập nhật báo cáo thất bại');
        console.log('Validate Failed:', info);
      });

    // props.setIsLoading(false);
  };

  // Function handle delete report
  const deleteReport = async (reportType: string, reportId: string) => {
    // Check data existing from localStorage
    // const existingData = localStorage.getItem(props.constDataName);
    // if (existingData) {
    //   const reports = JSON.parse(existingData);

    //   if (reportType === 'system') {
    //     // Info system item by click delete button
    //     const systemItem = reports.system.find((report: TypeColumnsSystem) => report.id === reportId);
    //     // Filter out reports that do not match the reportId in the system array
    //     const filteredSystemReports = reports.system.filter((report: TypeColumnsSystem) => report.id !== reportId);
    //     // Update the system array in the reports data
    //     reports.system = filteredSystemReports;
    //     message.success(`Xóa báo cáo ${systemItem.title} thành công`);
    //   } else if (reportType === 'user') {
    //     // Info user item by click delete button
    //     const userItem = reports.user.find((report: TypeColumnsUser) => report.id === reportId);
    //     // Filter out reports that do not match the reportId in the user array
    //     const filteredUserReports = reports.user.filter((report: TypeColumnsUser) => report.id !== reportId);
    //     // Update the user array in the reports data
    //     reports.user = filteredUserReports;
    //     message.success(`Xóa báo cáo ${userItem.title} thành công`);
    //   }

    //   // Save data back to localStorage after deleting
    //   localStorage.setItem(props.constDataName, JSON.stringify(reports));
    //   // Set value item report in dropdown
    //   props.setSystemReports(reports.system);
    //   props.setUserReports(reports.user);
    // }
    await reportSavedPresenter.deleteReportTemplate(reportSavedStore, reportId);
    if (reportSavedStore.deleteResponse.isSuccess) {
      message.success(reportSavedStore.deleteResponse.message);
      // lấy danh sách
      await reportSavedPresenter.getListReportTemplate(reportSavedStore, props.constDataName);
      if (reportSavedStore.listTemplateResponse.isSuccess) {
        const newData = reportSavedStore.listTemplateResponse.data.map((item) => ({
          id: item.reportTemplateId,
          value: JSON.parse(item.templateString),
          title: item.reportTemplateName,
          description: item.description,
          default: item.isDefault,
          resizable: JSON.parse(item.resizable),
        }));
        props.setUserReports(newData);
      }
    } else {
      message.error(reportSavedStore.deleteResponse.message);
    }
  };

  // Export function for parent component using (ref)
  useImperativeHandle(ref, () => ({
    showModal: showModal,
    showModalUpdate: showModalUpdate,
    handleGetReportSaved: handleGetReportSaved,
    deleteReport: deleteReport,
  }));

  return (
    <>
      {/* UI modal add template report */}
      <Modal
        title={
          <h1 className="text-[18px] uppercase">{openModalAddDocument.visible ? openModalAddDocument.title : ''}</h1>
        }
        open={openModalAddDocument.visible}
        okText="Lưu"
        cancelText="Đóng"
        onOk={handleSaveReport}
        onCancel={handleCancel}
      >
        <Form form={props.form} layout="vertical" name="form_in_modal_create" className="!pt-2">
          <Form.Item
            name="title"
            label="Tên mẫu báo cáo"
            className="!mb-4"
            rules={[
              {
                required: true,
                message: 'Tên mẫu báo cáo không được bỏ trống',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Mô tả" className="!mb-4">
            <Input type="textarea" />
          </Form.Item>
          <Form.Item name="default" valuePropName="checked" className="collection-create-form_last-form-item !mb-4">
            <Checkbox>Mặc định</Checkbox>
          </Form.Item>
        </Form>
      </Modal>

      {/* UI modal update template report */}
      <Modal
        title={
          <h1 className="text-[18px] uppercase">
            {openModalUpdateDocument.visible ? openModalUpdateDocument.title : ''}
          </h1>
        }
        open={openModalUpdateDocument.visible}
        okText="Lưu"
        cancelText="Hủy"
        onOk={handleUpdateReport}
        onCancel={handleCancel}
      >
        <Form form={props.form} layout="vertical" name="form_in_modal_update" className="!pt-2">
          <Form.Item
            name="title"
            label="Tên mẫu báo cáo"
            className="!mb-4"
            rules={[
              {
                required: true,
                message: 'Tên mẫu báo cáo không được bỏ trống',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Mô tả" className="!mb-4">
            <Input type="textarea" />
          </Form.Item>
          <Form.Item name="default" valuePropName="checked" className="collection-create-form_last-form-item !mb-4">
            <Checkbox>Mặc định</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

export default TemplateReportModal;
