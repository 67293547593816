import BoxContainer from 'base/ui/components/BoxContainer'
import { observer } from 'mobx-react'
import PageWrapper from 'pages/Components/PageWrapper'
import { Form, Input, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { SYSTEM_TITLE } from 'utils/constants';
import { getUserName } from 'utils/localStorageHelper';
import CustomButton from 'base/ui/components/Button';
import { FaSave } from 'react-icons/fa';
import { IAccountServices } from 'services/RawData/account_services';


const createChangePassword = (service: IAccountServices) => {
    return observer(() => {
        document.title = 'Đổi mật khẩu - ' + SYSTEM_TITLE;
        const [form] = Form.useForm()
        return (
            <PageWrapper
                breadcrumb={[{
                    text: "Đổi mật khẩu"
                }]}
                extras={
                    <CustomButton onClick={() => {
                        form.submit()
                    }}
                        type="primary">
                        <FaSave />
                        Lưu
                    </CustomButton>
                }
            >
                {/* <BoxContainer>
                    
                </BoxContainer> */}
                <BoxContainer fullHeight >
                    <SearchForm
                        initialValues={{ userName: getUserName() }}
                        form={form}
                        layout='horizontal'
                        column='col-1'
                        colon={false}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={async (values) => {
                            try {
                                const result = await service.changePasswordByUser(values)
                                if (result && result.isSuccess) {
                                    message.success(result.message)
                                } else {
                                    message.error(result?.message)
                                }
                            } catch (error) {

                            }
                        }}
                    >
                        <SearchItem name="userName" label="Tên tài khoản" tooltip="Tài khoản dùng để đăng nhập">
                            <Input disabled />
                        </SearchItem>
                        <SearchItem name='oldPassword' rules={[{ required: true }]} label="Mật khẩu cũ">
                            <Input.Password />
                        </SearchItem>
                        <SearchItem
                            hasFeedback
                            normalize={(value) => value.trim()}
                            rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mật khẩu mới' },
                            {
                                min: 12,
                                message: 'Mật khẩu có độ dài tối thiểu 12 ký tự.',
                            },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@$#%]).{12,}$/,
                                message: 'Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số hoặc 1 ký tự đặc biệt.',
                            },
                            ]}
                            name={'newPassword'}
                            label={"Mật khẩu mới"}

                        >
                            <Input.Password className="rounded-none" />
                        </SearchItem>
                        {/* Xác nhận mật khẩu mới */}
                        <SearchItem
                            hasFeedback
                            normalize={(value) => value.trim()}
                            rules={[
                                {
                                    required: true,
                                    message: 'Hãy nhập thông tin cho trườngXác nhận mật khẩu mới',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("Mật khẩu không khớp."));
                                    },
                                }),
                            ]}
                            name={'confirm'}
                            label={"Xác nhận mật khẩu mới"}
                        >
                            <Input.Password className="rounded-none" />
                        </SearchItem>
                    </SearchForm>

                </BoxContainer>

            </PageWrapper>
        )
    })
}


export default createChangePassword