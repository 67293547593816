import { Badge, Dropdown, Skeleton } from 'antd'
import { Bell } from 'base/ui/components/Icons'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FaBell } from 'react-icons/fa'
import { notificationPresenter, notificationStore, uiStore } from 'stores/root_store'
import { getAccountId } from 'utils/localStorageHelper'
import noNoti from 'assets/images/noNoti.jpg'
import { GoDotFill } from "react-icons/go";
import InfiniteScroll from 'react-infinite-scroll-component'
import { NotificationByUser } from 'models/Notification'
import { LuListChecks } from "react-icons/lu";
import { cloneDeep } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from 'base/routes/routes'

const Notification = () => {
    const navigate = useNavigate()
    const [pageIndex, setPageIndex] = useState(2)
    const [listNoti, setListNoti] = useState<NotificationByUser[]>([])

    const loadMore = async () => {
        if (pageIndex <= (notificationStore.listNotifications?.paging?.pagesCount || 0)) {
            await notificationPresenter.getListNotiByUser(notificationStore, {
                paging: {
                    pageIndex: pageIndex,
                    pageSize: 10
                },
                userId: getAccountId() || ''
            })
            setListNoti(prev => {
                const temp = [...prev, ...notificationStore.listNotifications?.data]
                return temp
            })
        }
    }


    return (
        <Dropdown
            arrow={{ pointAtCenter: true }}
            trigger={['click']}
            destroyPopupOnHide
            onOpenChange={async (open) => {
                if (open) {
                    await notificationPresenter.getListNotiByUser(notificationStore, {
                        paging: {
                            pageIndex: 1,
                            pageSize: 10
                        },
                        userId: getAccountId() || ''
                    })
                    setListNoti(notificationStore.listNotifications?.data)
                } else {
                    setListNoti([])
                    setPageIndex(2)
                }
            }}
            menu={{
                rootClassName: '[&_.ant-dropdown-menu-item]:!p-0 [&_.ant-dropdown-menu-item:hover]:!bg-white',
                items: listNoti.length ? [{
                    key: "0",
                    label: (
                        <div >
                            <div className='border-b px-2 flex justify-between items-end py-1 pb-2'>
                                <h2 className='text-xl ml-7 font-bold'>Thông báo</h2>
                                <LuListChecks
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (notificationStore.notiCount > 0) {
                                            notificationPresenter.readNotification({ userId: getAccountId() || '' })
                                            notificationPresenter.setNotificationCount(notificationStore, 0)
                                            setListNoti(prev => {
                                                const tempList = cloneDeep(prev)
                                                return tempList.map(item => ({
                                                    ...item,
                                                    isRead: true
                                                }))
                                            })
                                        }
                                    }}
                                    className='text-xl mr-2 hover:text-blue-700' />
                            </div>
                            <div id="scrollNoti" className='max-h-[230px] overflow-y-auto flex flex-col divide-y divide-slate-300'>
                                <InfiniteScroll
                                    dataLength={listNoti.length}
                                    next={() => {
                                        setPageIndex(prv => prv + 1)
                                        loadMore()
                                    }}
                                    hasMore={true}
                                    loader={pageIndex <= (notificationStore.listNotifications?.paging?.pagesCount || 0) ? (
                                        <div className='flex gap-2'>
                                            <Skeleton.Avatar active className='ml-1' />
                                            <Skeleton.Input block active />
                                        </div>
                                    ) : ''}

                                    scrollableTarget="scrollNoti"
                                >
                                    {listNoti.map(noti => {
                                        return (

                                            <div
                                                key={noti.id}
                                                onClick={async () => {
                                                    if (noti.isRead) {
                                                        notificationPresenter.setListPOAsynch(notificationStore, noti.listPO)
                                                        notificationPresenter.setCreateDate(notificationStore, noti.createTime)
                                                        navigate(ROUTES.PURCHASE_ORDER.LIST_PO.LINK)
                                                    } else {
                                                        const result = await notificationPresenter.readSingleNotification(noti.id)
                                                        if (result) {
                                                            notificationPresenter.setListPOAsynch(notificationStore, noti.listPO)
                                                            const count = cloneDeep(notificationStore.notiCount)
                                                            notificationPresenter.setNotificationCount(notificationStore, count - 1)
                                                            notificationPresenter.setCreateDate(notificationStore, noti.createTime)
                                                            navigate(ROUTES.PURCHASE_ORDER.LIST_PO.LINK)
                                                        }
                                                    }
                                                }}
                                                className='hover:bg-slate-100 px-3 py-1 text-default w-[400px] text-black flex items-center'>
                                                <div className='w-7'>
                                                    <FaBell className='text-xl text-blue-600' />
                                                </div>
                                                <div className='flex-1'>
                                                    <div className="font-medium flex items-center gap-2">
                                                        <span className='block w-full clamp-2'>{noti.titleNoti}</span>
                                                    </div>
                                                    <div className={`text-right text-xs mr-2 ${noti.isRead ? 'text-gray-600' : 'text-blue-600'}`}>{moment(noti.createTime).format("DD/MM/YYYY HH:mm:ss")}</div>
                                                </div>
                                                <div className='w-5'>
                                                    {!noti.isRead && <GoDotFill className='text-right text-xl text-blue-600' />}
                                                </div>
                                            </div>

                                        )
                                    })}
                                </InfiniteScroll>

                            </div>
                        </div>
                    )
                }] : [{
                    key: '1',
                    label: (
                        <div className='w-[400px] flex justify-center bg-white'>
                            <img width={150} src={noNoti} />
                        </div>
                    )
                }]

            }}>
            <Badge count={notificationStore.notiCount} size="small" offset={[-8, 0]}>
                <Bell
                    className={`${uiStore.theme.name === 'gray' ? '!fill-[#E3EDF6]' : '!fill-[#E3EDF6] lg:!fill-primary'} cursor-pointer`}
                />
            </Badge>
        </Dropdown>
    )
}

export default observer(Notification)

