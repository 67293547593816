import { Checkbox, FormInstance, Input, Radio, Space } from 'antd';
import { DataNode } from 'antd/es/tree';
import CustomInput from 'base/ui/components/CustomInput';
import { observer } from 'mobx-react';
import { SearchItem } from 'pages/Components/SearchBox';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { storeList } from 'services/RawData/commonMasterData_services';
import { accountPresenter, accountStore, commonMasterDataPresenter, commonMasterDataStore } from 'stores/root_store';
import { validateEmail, validatePhone, validateUsername } from 'utils/validateHelper';


type CreateUpdateAccountFormProps = {
    idParams?: string;
    form: FormInstance<any>;
    isCreateVendor?: boolean;
    isUpdateVendor?: boolean;
}

const CreateUpdateAccountForm: React.FC<CreateUpdateAccountFormProps> = (props) => {
    const { idParams, form, isCreateVendor, isUpdateVendor } = props;
    const { t } = useTranslation();
    // Nhà máy
    const [listStore, setListStore] = useState<storeList[]>([])
    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const getDataRoles = async () => {
        if (!accountStore.dataDropdownRoles.data.length) {
            await accountPresenter.getDropdownRoles(accountStore);
        }
        if (isCreateVendor) {
            form.setFieldValue('listRoleId', accountStore.dataDropdownRoles.data.find(r => r.value === "Nhà cung cấp")?.key)
        }
    }

    const getDropdownRoles = async () => {
        if (!commonMasterDataStore.listRoleByDepartment.length) {
            await commonMasterDataPresenter.getDropdownRoleByDepartment(commonMasterDataStore)
        }
        const tempt: DataNode[] = commonMasterDataStore.listRoleByDepartment.map((item, index) => {
            return {
                title: item.departmentName,
                key: item.departmentId,
                value: item.departmentId,
                children: item.roleItemResponses.map((i, idx) => ({
                    title: i.rolesName,
                    key: i.rolesId,
                    value: i.rolesId,
                })),
            };
        });
        setTreeData(tempt);
    }
    const getDropdownStore = async () => {
        await commonMasterDataPresenter.getDropDownStore2(commonMasterDataStore);
        if (commonMasterDataStore.companyRes.data.length) {
            const stores = commonMasterDataStore.companyRes.data.at(0)?.storeListResponses || []
            setListStore(stores)
            form.setFieldValue('listStoreId', [stores?.at(0)?.storeId])
        }
    }

    useEffect(() => {
        getDropdownStore()
        getDataRoles()
    }, []);
    return (
        <>
            {/* Tên nhân viên/Nhà cung cấp */}
            <SearchItem
                rules={[{ required: true, message: 'Hãy nhập thông tin cho trường "Tên nhân viên/Nhà cung cấp"' }]}
                name={'employeeName'}
                label={'Tên nhân viên/Nhà cung cấp'}
            >
                <CustomInput />
            </SearchItem>
            {/* tên tài khoản */}
            <SearchItem
                rules={[
                    { required: true, message: 'Hãy nhập thông tin cho trường "Tên tài khoản"' },
                    {
                        validator: validateUsername,
                    },
                ]}
                name={'userName'}
                label={t('permission.account.username')}
            >
                <CustomInput autoComplete='off' disabled={isCreateVendor ? false : idParams ? true : false} />
            </SearchItem>
            {/* Email */}
            <SearchItem
                name={'email'}
                label={'Email'}
                rules={[
                    { required: true, message: 'Hãy nhập thông tin cho trường "Email"' },
                    {
                        validator: validateEmail,
                    },
                ]}
            >
                <CustomInput />
            </SearchItem>
            {/* Nhà máy */}
            <SearchItem
                name={'listStoreId'}
                label={'Nhà máy'}
                rules={[{ required: true, message: 'Chưa chọn "Nhà máy".' }]}
            >
                {/* <Tree checkable treeData={treeData} /> */}
                <Checkbox.Group>
                    <Space direction='vertical'>
                        {listStore.map((data, index) => (
                            <Checkbox key={index} value={data.storeId}>{data.storeName}</Checkbox>
                        ))}
                    </Space>
                </Checkbox.Group>
            </SearchItem>
            {/* Số điện thoại */}
            <SearchItem
                name={'phone'}
                label={"Số điện thoại"}
                rules={[
                    {
                        validator: validatePhone,
                    },
                ]}
            >
                <Input />
            </SearchItem>
            {/*Tài khoản nhà cung cấp */}
            {(isCreateVendor || isUpdateVendor) && (
                <SearchItem
                    name={'isSupplier'}
                    label={'Tài khoản nhà cung cấp'}
                // valuePropName='checked'
                >
                    <Checkbox checked={true} />
                </SearchItem>
            )}

            {/* mật khẩu */}
            {!idParams &&
                <SearchItem
                    hasFeedback
                    rootClassName='col-start-1'
                    rules={[
                        { required: true, message: 'Hãy nhập thông tin cho trường "Mật khẩu"' },
                        {
                            min: 12,
                            message: 'Mật khẩu có độ dài tối thiểu 12 ký tự.',
                        },
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@$#%]).{12,}$/,
                            message: 'Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số hoặc 1 ký tự đặc biệt.',
                        },
                    ]}
                    name={'password'}
                    label={t('permission.account.password')}
                    normalize={(value) => value.trim()}
                >
                    <Input.Password className="rounded-none" />
                </SearchItem>}
            {isCreateVendor && !isUpdateVendor && (
                <SearchItem
                    rootClassName='col-start-1'
                    hasFeedback
                    rules={[
                        { required: true, message: 'Hãy nhập thông tin cho trường "Mật khẩu"' },
                        {
                            min: 12,
                            message: 'Mật khẩu có độ dài tối thiểu 12 ký tự.',
                        },
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@$#%]).{12,}$/,
                            message: 'Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số hoặc 1 ký tự đặc biệt.',
                        },
                    ]}
                    name={'password'}
                    label={t('permission.account.password')}
                    normalize={(value) => value.trim()}
                >
                    <Input.Password className="rounded-none" />
                </SearchItem>
            )}
            {/* xác nhận mật khẩu */}
            {idParams ? null : (
                <>
                    <SearchItem
                        hasFeedback
                        dependencies={['password']}
                        name={'confirm'}
                        label={t('permission.account.confirm-password')}
                        normalize={(value) => value.trim()}
                        rules={[
                            {
                                required: true,
                                message: t('message.please-check-password'),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('message.password-incorrect')));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </SearchItem>
                </>
            )}
            {isCreateVendor && (
                <SearchItem
                    hasFeedback
                    dependencies={['password']}
                    name={'confirm'}
                    label={t('permission.account.confirm-password')}
                    normalize={(value) => value.trim()}
                    rules={[
                        {
                            required: true,
                            message: t('message.please-check-password'),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('message.password-incorrect')));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </SearchItem>
            )}
            {/* Nhóm người dùng */}
            {(!isUpdateVendor || !isCreateVendor) && (
                <SearchItem
                    hidden={isCreateVendor || isUpdateVendor ? true : false}
                    rules={[{ required: true, message: `Chọn ít nhất 1 "Nhóm người dùng".` }]}
                    name={'listRoleId'} label={'Nhóm người dùng'} rootClassName='col-start-1'>
                    <Checkbox.Group className='w-full' >
                        <Space direction='vertical'>
                            {accountStore.dataDropdownRoles.data.filter(item => item.value !== "Nhà cung cấp").map((data, index) => (
                                <Checkbox onChange={(e) => {
                                    if (e.target.checked && data.value === "Nhà cung cấp") {
                                        form.setFieldValue('isSupplier', true);
                                    } else if (!e.target.checked && data.value === "Nhà cung cấp") {
                                        form.setFieldValue('isSupplier', false);
                                    }
                                }} key={index} value={data.key}>{data.value}</Checkbox>
                            ))}
                        </Space>
                    </Checkbox.Group>
                </SearchItem>
            )}
            {/* Trạng thái */}
            <SearchItem hidden={idParams && !isCreateVendor ? false : true} name={'actived'} label={t('permission.account.status')}>
                <Radio.Group>
                    <Radio value={true}>{t('radio-btn.active')}</Radio>
                    <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                </Radio.Group>
            </SearchItem>

            {/* <SearchItem
      rules={[{ required: true, message: `Chọn ít nhất 1 "Phòng ban".` }]}
      name={'listRoleId'} label={'Phòng ban'} rootClassName='col-start-1'
      valuePropName="checkedKeys"
      trigger="onCheck"
    >
      <Tree checkable treeData={treeData} />
    </SearchItem> */}
        </>
    )
}

export default observer(CreateUpdateAccountForm)