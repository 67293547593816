import React, { useCallback, useEffect, useState } from 'react'
import { CardBox } from './cardBox'
import { SearchItem } from 'pages/Components/SearchBox'
import { Form, FormInstance, Input, Select } from 'antd'
import { validateLicensePlate, validatePhone } from 'utils/validateHelper'
import { observer } from 'mobx-react'
import { commonPresenter, commonStore, driverPresenter, driverStore } from 'stores/root_store'
import { Driver } from 'services/RawData/driver_services'
import { cloneDeep } from 'lodash'
import { debounce } from 'utils/debounce'

type DriverInformationProps = {
    disabledField: (isDisable?: boolean) => boolean;
    formCreate: FormInstance<any>;
}

const DriverInformation: React.FC<DriverInformationProps> = (props) => {
    const { disabledField, formCreate } = props
    const vendorNumber = Form.useWatch("vendorNumber", formCreate)
    // danh sách tài xế 
    const [dropdownDriver, setDropdownDriver] = useState<Driver[]>([])
    // loading Search driver
    const [searchDriverLoading, setSearchDriverLoading] = useState(false)
    // Dropdown tài xế
    const getListDriver = async (driverName?: string) => {
        setSearchDriverLoading(true)
        const request = {
            paging: {
                pageIndex: 1,
                pageSize: 20,
            },
            driverName: driverName,
            actived: true,
            vendorNumber: vendorNumber
        }
        await driverPresenter.searchDriver(driverStore, request);
        setSearchDriverLoading(false)
        if (driverStore.searchDriverResponse?.isSuccess) {
            // AVNMR-67
            // Sửa phiếu_Thêm phiếu _ Dropdown tài xế Thêm Số bằng lái
            const cloneDrivers = cloneDeep(driverStore.searchDriverResponse?.data)
            cloneDrivers.forEach(driver => {
                Object.assign(driver, { driverName: `${driver.driverName} | ${driver.licenseNumber}` })
            })
            setDropdownDriver(cloneDrivers);
        }
        return driverStore.searchDriverResponse.data
    }
    const handleSearchDriver = useCallback(debounce(getListDriver, 300), [searchDriverLoading])
    // danh sách kết quả đào tạo
    const getTrainingResult = async () => {
        if (!commonStore.dropdownTrainingResult.length) {
            await commonPresenter.getDropdownTrainingResult(commonStore)
        }
    }

    useEffect(() => {
        if (vendorNumber) {
            getListDriver()
            getTrainingResult()
        }
    }, [vendorNumber]);

    return (
        <CardBox title="Thông tin tài xế" >
            <div className="grid grid-cols-2 gap-x-default">
                {/* -- Tên tài xế ------- */}
                <SearchItem name={'driverName'} label='Tên tài xế'
                    rules={[{ required: true, message: 'Vui lòng nhập "Tên tài xế".' }]}
                >
                    <Select
                        disabled={disabledField(true)}
                        loading={searchDriverLoading}
                        options={dropdownDriver}
                        fieldNames={{ label: 'driverName', value: 'driverName' }}
                        filterOption={false}
                        showSearch
                        allowClear
                        onSearch={async (value) => {
                            handleSearchDriver(value || undefined)
                            if (value) {
                                formCreate.setFieldValue('driverName', value)
                            }
                        }}
                        onClear={() => {
                            formCreate.setFieldsValue({
                                driverName: undefined,
                                phone: undefined,
                                licenseNumber: undefined,
                                result: undefined
                            })
                            getListDriver()
                        }}
                        onChange={(_, option) => {
                            if (option && !Array.isArray(option)) {
                                formCreate.setFieldsValue({
                                    driverName: option.driverName,
                                    phone: option.phone,
                                    licenseNumber: option.licenseNumber,
                                    result: option.trainingResult
                                })
                            }
                        }}
                    />
                </SearchItem>
                {/* -- Số điện thoại ------ */}
                {/* Số điện thoại */}
                <SearchItem name={'phone'} label='Số điện thoại'
                    rules={[
                        {
                            validator: validatePhone,
                        },
                    ]}>
                    <Input disabled={disabledField(true)} />
                </SearchItem>
                {/* số xe */}
                <SearchItem name={'licensePlate'} label='Số xe'
                    rules={[{ required: true, message: 'Vui lòng nhập "Số xe".' }, { validator: validateLicensePlate }]}
                >
                    <Input disabled={disabledField(true)} />
                </SearchItem>
                {/* Số bằng lái */}
                <SearchItem name={'licenseNumber'} label='Số bằng lái xe '
                // rules={[{ required: true, message: 'Vui lòng nhập "Số bằng lái".' }]}
                >
                    <Input disabled={disabledField(true)} />
                </SearchItem>
                {/* Kết quả đào tạo an toàn */}
                <SearchItem initialValue={"DAT"} name={'result'} label='Kết quả đào tạo an toàn'
                    rules={[{ required: true, message: 'Vui lòng chọn "Kết quả đào tạo an toàn".' }]}
                >
                    <Select disabled={disabledField(true)} options={commonStore.dropdownTrainingResult} fieldNames={{ label: 'catalogName', value: 'catalogCode' }} />
                </SearchItem>
            </div>
        </CardBox>
    )
}

export default observer(DriverInformation)