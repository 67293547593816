import { DatePicker, FormInstance, Input, Select } from 'antd'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { SearchItem } from 'pages/Components/SearchBox'
import SearchMultiplePopup, { SearchMultiplePopupRef } from 'pages/synchPO/SearchMultiplePopup'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { CommonModel } from 'services/Main/common_services'
import { commonMasterDataPresenter, commonMasterDataStore, commonPresenter, commonStore } from 'stores/root_store'
import { DATE_FORMAT_DEFAULT } from 'utils/constants'
import { debounce } from 'utils/debounce'
import { dropdownStatus } from 'utils/dropdown'
import { getAccountId } from 'utils/localStorageHelper'

type SearchPOFormProps = {
    searchForm: FormInstance<any>;
    setIsLoading: (loading: boolean) => void
}

const SearchPOForm: React.FC<SearchPOFormProps> = (props) => {
    const { searchForm, setIsLoading } = props
    // dropdown po
    const [dropdownPo, setDropdownPO] = useState<CommonModel[]>([])
    // mở modal search nhiều số po
    const [showModalPO, setShowModalPO] = useState(false)
    // mở modal search nhiều số pr
    const [showModalPR, setShowModalPR] = useState(false)
    // mở modal search nhiều số product
    const [showModalProduct, setShowModalProduct] = useState(false)
    // mở modal search nhiều mã nhà cung cấp
    const [showModalVendor, setShowModalVendor] = useState(false)
    // Dropdown nhà cung cấp
    // const [listVendor, setListVendor] = useState<CommonModel[]>([])
    // disable nhập ngày khi chọn khác "Custom"
    const [disableDatePicker, setDisableDatePicker] = useState(false)
    // 
    const [disableCreateTime, setDisableCreateTime] = useState(false)

    const searchMultiPORef = useRef<SearchMultiplePopupRef>(null)
    const searchMultiPRRef = useRef<SearchMultiplePopupRef>(null)
    const searchMultiProductRef = useRef<SearchMultiplePopupRef>(null)
    const searchMultiVendorRef = useRef<SearchMultiplePopupRef>(null)

    // Dropdown nhà cung cấp
    // const getDropdownVendor = async (keyword?: string) => {
    //     await commonPresenter.getDropdownVendor(commonStore, { keyword: keyword, takeNumber: 20 })
    //     if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
    //         setListVendor(commonStore.commonRespone.data)
    //     }
    // }
    // const searchVendor = useCallback(debounce(getDropdownVendor, 300), [])

    // Danh sách common date
    const getListCommom = async () => {
        if (!commonStore.listCommonDate.length) {
            await commonPresenter.getListCommonDate(commonStore)
        }
    }
    // DropdownPO type
    const getDropdownPOType = async () => {
        if (!commonStore.dropdownPOType.length) {
            await commonPresenter.getDropdownPOType(commonStore)
        }
    }
    // Danh sách nhà máy
    const getDropdownSaleOrg = async () => {
        if (!commonStore.commonStore.length) {
            await commonPresenter.getSeleOrgByAccountId(commonStore, getAccountId())
            searchForm.setFieldValue('saleOrgCode', commonStore.commonStore[0].key)
        }
    }
    // Dropdown PO
    const getDropdownPO = async (keyword?: string) => {
        const lists = await commonPresenter.getDropdownPO(keyword)
        setDropdownPO(lists)
    }
    const getDateByCommonDocumentDate = async (commonDate: string) => {
        setDisableDatePicker(true)
        await commonPresenter.getDateByCommonDate(commonStore, commonDate)
        if (commonStore.commonDate.fromDate) {
            searchForm.setFieldsValue({ documentDateFrom: dayjs(commonStore.commonDate.fromDate, "YYYY-MM-DD"), documentDateTo: dayjs(commonStore.commonDate.toDate, "YYYY-MM-DD") })
        }
    }
    const getDateByCommonCreateTime = async (commonDate: string) => {
        setDisableCreateTime(true)
        await commonPresenter.getDateByCommonDate(commonStore, commonDate)
        if (commonStore.commonDate.fromDate) {
            searchForm.setFieldsValue({ createTimeFrom: dayjs(commonStore.commonDate.fromDate, "YYYY-MM-DD"), createTimeTo: dayjs(commonStore.commonDate.toDate, "YYYY-MM-DD") })
        }
    }

    const getProductType = async () => {
        if (!commonMasterDataStore.productTypes.length) {
            await commonMasterDataPresenter.getDropdownProductType(commonMasterDataStore)
        }
    }


    useEffect(() => {

        Promise.allSettled([
            getProductType(),
            getListCommom(),
            getDropdownSaleOrg(),
            // getDropdownVendor(),
            // getTrainingResult(),
            getDropdownPOType(),
            getDropdownPO()
        ])
    }, []);


    return (
        <>
            <button type="submit" hidden></button>
            {/* Purchase order */}
            <div className="relative">
                <SearchItem name={'purchaseOrderCodes'} label={'Số PO'}>
                    <Select
                        allowClear
                        showSearch
                        options={dropdownPo}
                        fieldNames={{ value: 'key', label: 'value' }}
                        mode="multiple"
                        filterOption={(input, option) =>
                            (option?.value?.toLowerCase() ?? '').includes(input.toLowerCase())
                        }
                        placeholder="Vui lòng chọn"
                        onClear={() => {
                            searchMultiPORef.current?.clearData()
                        }}
                    />
                </SearchItem>
                <div
                    onClick={() => {
                        setShowModalPO(true)
                    }}
                    className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                    <HiOutlineExternalLink className="text-xl" />
                </div>
            </div>
            {/*Số PR */}
            <div className="relative h-max">
                <SearchItem name={'prNumber'} label={'Số PR'}>
                    <Input
                        placeholder="Nhập số PR"
                        allowClear
                        onChange={(e) => {
                            if (!e.target.value) {
                                searchMultiPRRef.current?.clearData()
                            }
                        }}
                    />
                </SearchItem>
                <div
                    onClick={() => {
                        setShowModalPR(true)
                    }}
                    className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                    <HiOutlineExternalLink className="text-xl" />
                </div>
            </div>
            {/* Document type */}
            <SearchItem name={'documentType'} label={'Loại PO'}>
                <Select
                    fieldNames={{ value: 'catalogCode', label: 'catalogFmt' }}
                    options={commonStore.dropdownPOType}
                    allowClear showSearch
                    // search dữ liệu không call api
                    filterOption={(input, option) =>
                        (option?.catalogFmt?.toLowerCase() ?? '').includes(input.toLowerCase())
                    }
                    placeholder="Vui lòng chọn"
                />
            </SearchItem>
            {/* Nhà máy */}
            <SearchItem initialValue={commonStore.commonStore.at(0)?.key} name={'saleOrgCode'} label={'Nhà máy'}>
                <Select
                    options={commonStore.commonStore}
                    allowClear={false} fieldNames={{ value: 'key', label: 'value' }}
                    placeholder="Vui lòng chọn"
                />
            </SearchItem>
            {/* Mã hàng hóa */}
            <div className="relative">
                <SearchItem name={'productCode'} label={'Mã hàng hóa'} start>
                    <Input
                        placeholder="Nhập mã hàng hóa"
                        allowClear
                        onChange={(e) => {
                            if (!e.target.value) {
                                searchMultiProductRef.current?.clearData()
                            }
                        }}
                    />
                </SearchItem>
                <div
                    onClick={() => {
                        setShowModalProduct(true)
                    }}
                    className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                    <HiOutlineExternalLink className="text-xl" />
                </div>
            </div>
            {/* Tên hàng hóa */}
            <SearchItem name={'productName'} label={'Tên hàng hóa'}>
                <Input placeholder="Nhập tên hàng hóa" />
            </SearchItem>
            {/* Loại hàng hóa */}
            <SearchItem name={'productType'} label={'Loại hàng hóa'}>
                <Select
                    options={commonMasterDataStore?.productTypes.map((type) => ({
                        label: type.value,
                        value: type.key
                    }))}
                    allowClear
                    filterOption={false}
                    placeholder="Vui lòng chọn"
                />
            </SearchItem>
            {/* Nhà cung cấp */}
            {/* <SearchItem name={'vendorNumber'} label={'Nhà cung cấp'}>
                <Select
                    // fieldNames={{ value: 'key', label: 'value' }}
                    popupMatchSelectWidth={false}
                    options={listVendor.map(item => ({ value: item.key, label: item.value }))}
                    allowClear showSearch
                    filterOption={false}
                    onSearch={(value) => searchVendor(value || undefined)}
                    placeholder="Vui lòng chọn"
                />
            </SearchItem> */}
            {/* Mã Nhà cung cấp */}
            <div className="relative col-start-1">
                <SearchItem name={'vendorNumber'} label={'Mã nhà cung cấp'} >
                    <Input
                        placeholder="Nhập mã nhà cung cấp"
                        allowClear
                        onChange={(e) => {
                            if (!e.target.value) {
                                searchMultiVendorRef.current?.clearData()
                            }
                        }}
                    />
                </SearchItem>
                <div
                    onClick={() => {
                        setShowModalVendor(true)
                    }}
                    className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                    <HiOutlineExternalLink className="text-xl" />
                </div>
            </div>
            {/* Tên nhà cung cấp */}
            <SearchItem name={'vendorName'} label={'Tên nhà cung cấp'} >
                <Input placeholder="Nhập tên nhà cung cấp" />
            </SearchItem>

            {/* trạng thái */}
            <SearchItem initialValue={true} label={"Trạng thái"} name={'actived'}>
                <Select options={dropdownStatus} placeholder={"--Tất cả--"} allowClear />
            </SearchItem>

            {/* Ngày giao hàng dự kiến*/}
            <SearchItem
                initialValue={'Today'}
                name="common" label={'Ngày giao hàng dự kiến'} start>
                <Select options={commonStore.listCommonDate} fieldNames={{ label: 'value', value: 'key' }} onChange={async (value) => {
                    if (value && value !== "Custom") {
                        getDateByCommonDocumentDate(value)
                    } else {
                        setDisableDatePicker(false)
                        searchForm.setFieldsValue({ documentDateFrom: undefined, documentDateTo: undefined })
                    }
                }} />
            </SearchItem>
            {/* Từ ngày */}
            <SearchItem
                initialValue={dayjs()}
                name={'documentDateFrom'} label={'Từ ngày'} start
                dependencies={['documentDateTo']}
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value || getFieldValue('documentDateTo')) {
                                return Promise.resolve()
                            }
                            return Promise.reject(new Error('Chưa nhập giá trị "Từ ngày".'));;
                        },
                    })
                ]}
            >
                <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
            </SearchItem>
            {/* Đến ngày */}
            <SearchItem
                initialValue={dayjs()}
                name={'documentDateTo'} label={'Đến ngày'}
                dependencies={['documentDateFrom']}
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value || getFieldValue('documentDateFrom')) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Chưa nhập giá trị "Đến ngày".'));
                        },
                    })
                ]}
            >
                <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
            </SearchItem>
            {/* Ngày đồng bộ*/}
            <SearchItem initialValue={'Custom'} name="common1" label={'Ngày đồng bộ'} start>
                <Select options={commonStore.listCommonDate} fieldNames={{ label: 'value', value: 'key' }} onChange={async (value) => {
                    if (value && value !== "Custom") {
                        getDateByCommonCreateTime(value)
                    } else {
                        setDisableCreateTime(false)
                        searchForm.setFieldsValue({ createTimeFrom: undefined, createTimeTo: undefined })
                    }
                }} />
            </SearchItem>
            {/* Từ ngày */}
            <SearchItem
                name={'createTimeFrom'} label={'Từ ngày'} start
            >
                <DatePicker changeOnBlur disabled={disableCreateTime} format={DATE_FORMAT_DEFAULT} className="w-full" />
            </SearchItem>
            {/* Đến ngày */}
            <SearchItem
                name={'createTimeTo'} label={'Đến ngày'}
            >
                <DatePicker changeOnBlur disabled={disableCreateTime} format={DATE_FORMAT_DEFAULT} className="w-full" />
            </SearchItem>

            {/* Tìm kiếm PO */}
            <SearchMultiplePopup
                ref={searchMultiPORef}
                title="PO"
                open={showModalPO}
                onOpenChange={setShowModalPO}
                onOk={(value) => {
                    const listPO = value.split(',');
                    searchForm.setFieldValue('purchaseOrderCodes', listPO)
                }}
                onReset={() => {
                    searchForm.setFieldValue('purchaseOrderCodes', undefined)
                }}
            />
            {/* Tìm kiếm PR */}
            <SearchMultiplePopup
                ref={searchMultiPRRef}
                title="PR"
                open={showModalPR}
                onOpenChange={setShowModalPR}
                onOk={(value) => {
                    searchForm.setFieldValue('prNumber', value)
                }}
                onReset={() => {
                    searchForm.setFieldValue('prNumber', undefined)
                }}
            />
            {/* Tìm kiếm Product */}
            <SearchMultiplePopup
                ref={searchMultiProductRef}
                title="Hàng hóa"
                open={showModalProduct}
                onOpenChange={setShowModalProduct}
                onOk={(value) => {
                    searchForm.setFieldValue('productCode', value)
                }}
                onReset={() => {
                    searchForm.setFieldValue('productCode', undefined)
                }}
            />
            {/* Tìm kiếm nhà cung câp */}
            <SearchMultiplePopup
                ref={searchMultiVendorRef}
                title="Nhà cung cấp"
                open={showModalVendor}
                onOpenChange={setShowModalVendor}
                onOk={(value) => {
                    searchForm.setFieldValue('vendorNumber', value)
                }}
                onReset={() => {
                    searchForm.setFieldValue('vendorNumber', undefined)
                }}
            />
        </>
    )
}

export default observer(SearchPOForm)