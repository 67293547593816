import { MASTER_DATA_API_URL } from "utils/constants";
import { get, post } from '../../utils/api';
import { getAccountId } from "utils/localStorageHelper";
import { DataResponse } from "services/response";

//Interface của common service
export interface CommonModel {
  key: string;
  value: string;
  name?: string;
  data?: boolean | string;
  unit?: string;
  type?: string;
  code?: string;
  options?: any;
  // Company list
  companyId?: string;
}

export interface CommonDateModel {
  fromDate: string;
  toDate: string;
}


//Type của common service
export type CommonDataResponse = {
  data: CommonModel[];
  message: string;
  isSuccess: boolean;
};

export interface CommonCatalogModel {
  catalogCode: string;
  catalogName: string;
  catalogFmt?: string;
  orderIndex: number;
}


// ============ Khai báo phương thức =====================
export interface ICommonServices {
  // =====================COMMON DATE===========================
  // lấy ngày từ common date
  getDateByCommonDate(date: string): Promise<CommonDateModel>;
  getCommonDate(type?: "CommonDate" | 'CommonDate2' | "Future"): Promise<CommonDataResponse>;
  // =================================================================
  // Lấy data danh sách công ty
  getListSaleOrg(): Promise<CommonDataResponse>;
  // Danh sách saleorg by account id
  getSeleOrgByAccountId(accountId: string): Promise<CommonDataResponse>
  // Danh sách Tỉnh thành phố
  getDropdownProvince(data: { keyword?: string }): Promise<CommonDataResponse>
  // lấy danh sách catalog by code
  getCatalogByCode(code: string): Promise<DataResponse<Array<CommonCatalogModel>>>
  // Danh sách nhà cung cấp
  getDropdownVendor(data?: { keyword?: string; takeNumber?: number }): Promise<CommonDataResponse>
  // Dropdown trạng thái luồng duyệt
  getDropdownTaskStatus(): Promise<CommonDataResponse>
  // lấy dropdown catalog by catalogTypeCode (response: {key, value})
  getDropdownCatalogByCatalogTypeCode(catalogTypeCode: string): Promise<CommonDataResponse>
  // lấy danh sách PO code
  getDropdownPOCode(key?: string): Promise<CommonDataResponse>
  // danh sách product
  getDropdownProduct(keyword?: string): Promise<CommonDataResponse>
}
// Hàm khởi tạo các phương nhà của NKMH service
export function createCommonServices(): ICommonServices {
  return {

    // =================COMMON DATE===========================================================================
    // lấy ngày từ common date
    getDateByCommonDate: async (date) => {
      return (
        await get(
          MASTER_DATA_API_URL,
          `CommonDate/GetDateByCommonDate?CommonDate=${date}`))
        .data;
    },
    getCommonDate: async (type) => {
      return (await get(MASTER_DATA_API_URL, 'commondate/get-common-date', type && { Type: type })).data

    },
    // ============================================================================================
    // Hàm lấy data danh sách công ty
    getListSaleOrg: async () => {
      return (
        await get(
          MASTER_DATA_API_URL,
          `Common/select-company`))
        .data;
    },
    // Danh sách saleorg by account id
    getSeleOrgByAccountId: async (accountId) => {
      return (
        await get(
          MASTER_DATA_API_URL,
          `common/get-saleorg-by-account/${accountId}`))
        .data;
    },
    // Danh sách Tỉnh thành phố
    getDropdownProvince: async (data) => {
      return (
        await get(
          MASTER_DATA_API_URL,
          `common/get-dropdown-province`, data))
        .data;
    },
    // lấy danh sách catalog by code
    getCatalogByCode: async (code) => {
      return (await get(MASTER_DATA_API_URL, `catalog/get-by-catalogtypecode`, { catalogtypecode: code })).data;
    },
    // Danh sách nhà cung cấp
    getDropdownVendor: async (data) => {
      return (await get(MASTER_DATA_API_URL, `common/list-vendor`, { ...data })).data;
    },
    // Dropdown trạng thái luồng duyệt
    getDropdownTaskStatus: async () => {
      return (await get(MASTER_DATA_API_URL, `common/get-dropdown-task-status`)).data;
    },
    // lấy dropdown catalog by catalogTypeCode (response: {key, value})
    getDropdownCatalogByCatalogTypeCode: async (catalogTypeCode) => {
      return (await get(MASTER_DATA_API_URL, `common/get-ctl-by-type/${catalogTypeCode}`)).data;
    },
    // lấy danh sách PO code
    getDropdownPOCode: async (key) => {
      return (await get(MASTER_DATA_API_URL, `common/get-dropdown-po`, { keyword: key ? key : undefined })).data;
    },
    // danh sách product
    getDropdownProduct: async (key) => {
      return (await get(MASTER_DATA_API_URL, `common/get-dropdown-product-v2`, { keyword: key ? key : undefined })).data;
    },
  };
}
