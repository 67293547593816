import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { dataReport } from '../ListRigistration/mock'
import { DatePicker, Form, Input, Select, Tag } from 'antd'
import { ActionType, ProColumns } from '@ant-design/pro-components'
import { useResizableColumns } from 'utils/reportHelper'
import PageWrapper from 'pages/Components/PageWrapper'
import CustomButton from 'base/ui/components/Button';
import { DATE_FORMAT_DEFAULT, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants'
import { SearchModel } from 'pages/model'
import { Filter } from 'base/ui/components/Icons'
import BoxContainer from 'base/ui/components/BoxContainer'
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable'
import { ExportButton } from 'pages/Components/FunctionButton'
import SaveReportForm from 'pages/Components/reportForm'
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox'
import { formatNumber } from 'utils/formatHelper'
import { commonPresenter, commonStore, reportPresenter, reportStore } from 'stores/root_store'
import { HiSearch } from 'react-icons/hi'
import dayjs from 'dayjs'
import { CommonModel } from 'services/Main/common_services'
import { debounce } from 'utils/debounce'
import { usePermission } from 'hooks/usePermission'
import { checkPermissionWithId } from 'utils/permissionHelper'
import { SearchDeliveryResultReport } from 'services/Main/report_services'
import { DeliveryRegistrationResulItem, DeliveryResultReport } from 'models/Report'
import { Link } from 'react-router-dom'
import { ROUTES } from 'base/routes/routes'
import { cloneDeep, omit } from 'lodash'
import { removeEmptyValues } from 'utils/transformHelper'


type ColumnRenderType = "tag" | "customTag" | "date" | "number" | "link" | undefined

const initialSearch: SearchDeliveryResultReport = {
    paging: {
        pageSize: PAGE_SIZE_DEFAULT,
        pageIndex: 1,
        orderBy: undefined,
        orderByDesc: undefined,
    },
    deliveryDateFrom: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    deliveryDateTo: dayjs().format('YYYY-MM-DD')
}

const createDeliveryResult = () => {
    return observer(() => {
        document.title = 'Báo cáo - ' + SYSTEM_TITLE;
        usePermission('INDEX')
        const isExport = checkPermissionWithId("EXPORT")

        // ========== SEARCH =================
        const [searchForm] = Form.useForm()
        // mở drawer search
        const [openDrawer, setOpenDrawer] = useState(false)
        // disable nhập ngày khi chọn khác "Custom"
        const [disableDatePicker, setDisableDatePicker] = useState(false)
        const [disableDatePickerDelivery, setDisableDatePickerDelivery] = useState(false)
        const [loading, setLoading] = useState(true)
        // Dropdown nhà cung cấp
        const [listVendor, setListVendor] = useState<CommonModel[]>([])
        // data search (phân trang)
        const [dataPaging, setDataPaging] = useState<SearchDeliveryResultReport>(initialSearch);
        // dữ liệu trên bảng 
        const [dataTable, setDataTable] = useState<DeliveryResultReport[]>([])

        const getListCommom = async () => {
            if (!commonStore.listCommonDate.length) {
                await commonPresenter.getListCommonDate(commonStore)
            }
        }
        // lấy ngày từ common date
        const getDateByCommonDate = async (commonDate: string) => {
            setDisableDatePicker(true)
            await commonPresenter.getDateByCommonDate(commonStore, commonDate)
            if (commonStore.commonDate.fromDate) {
                searchForm.setFieldsValue({ receiveDateFrom: dayjs(commonStore.commonDate.fromDate, "YYYY-MM-DD"), receiveDateTo: dayjs(commonStore.commonDate.toDate, "YYYY-MM-DD") })
            }
        }

        const getDateByCommonDateDelivery = async (commonDate: string) => {
            setDisableDatePicker(true)
            await commonPresenter.getDateByCommonDate(commonStore, commonDate)
            if (commonStore.commonDate.fromDate) {
                searchForm.setFieldsValue({ deliveryDateFrom: dayjs(commonStore.commonDate.fromDate, "YYYY-MM-DD"), deliveryDateTo: dayjs(commonStore.commonDate.toDate, "YYYY-MM-DD") })
            }
        }

        // Dropdown nhà cung cấp
        const getDropdownVendor = async (keyword?: string) => {
            await commonPresenter.getDropdownVendor(commonStore, { keyword: keyword, takeNumber: 20 })
            if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
                setListVendor(commonStore.commonRespone.data)
            }
        }
        const searchVendor = useCallback(debounce(getDropdownVendor, 300), [])
        // Dropdown trạng thái luồn duyệt
        const getTaskStatus = async () => {
            if (!commonStore.dropdownTaskStatus.length) {
                await commonPresenter.getDropdownTaskStatus(commonStore)
            }
        }
        // Dropdown trạng thái nhận hàng
        const getReceiveStatus = async () => {
            if (!commonStore.dropdownReceiveStatus.length) {
                await commonPresenter.getDropdownReceiveStatus(commonStore)
            }
        }

        // search báo cáo
        const getDataTable = async (dataSearch: SearchDeliveryResultReport) => {
            await reportPresenter.getReportDeliveryResult(reportStore, dataSearch)
            if (reportStore.deliveryResultRes?.isSuccess) {
                setDataTable(reportStore.deliveryResultRes?.data)
                return reportStore.deliveryResultRes?.data
            }
            return []
        }

        useEffect(() => {
            Promise.allSettled([
                getListCommom(),
                getDropdownVendor(),
                getTaskStatus(),
                getReceiveStatus()
            ]).then(() => { setLoading(false) })
        }, []);

        // =========== TABLE && MẪU BÁO CÁO =================
        const getColumnRenderProps = (dataIndex: keyof DeliveryRegistrationResulItem,
            option?: {
                type?: ColumnRenderType;
                align?: string;
                truncate?: "truncate";
                color?: string
            }): ProColumns<DeliveryResultReport> => ({
                onCell: () => ({ className: `!p-0 ${option?.align}` }),
                render(_, record) {
                    const { reportDeliveryRegistrationResulItems } = record
                    return reportDeliveryRegistrationResulItems.length ?
                        (
                            <div className='-my-1 divide-y'>
                                {
                                    reportDeliveryRegistrationResulItems.map((item, index) => {
                                        return (
                                            <div key={index} className={`w-full py-1 px-2 ${option?.truncate}`}>
                                                {option?.type === 'tag' &&
                                                    <Tag color={getDeliveryStatusColor(item[dataIndex] + '')} bordered className="w-full !text-xs !text-center font-medium !py-0.25">{item[dataIndex]}</Tag>
                                                }
                                                {option?.type === 'customTag' &&
                                                    <div className="w-full !text-xs !text-center font-medium !py-0.25 border rounded-sm"
                                                        style={{
                                                            borderColor: item.color + '80',
                                                            color: item.color,
                                                            backgroundColor: item.color + '1a'
                                                        }}
                                                    >{item[dataIndex]}</div>
                                                }
                                                {option?.type === 'number' && formatNumber(Number(item[dataIndex]), 3)}
                                                {option?.type === 'link' && <Link to={ROUTES.PURCHASE_ORDER.LIST_PO.EDIT_VOTE.LINK + '/' + item.deliveryRegistrationId}>{item[dataIndex]}</Link>}
                                                {option?.type === 'date' && <>
                                                    {item[dataIndex] ? dayjs(item[dataIndex]).format("DD/MM/YYYY HH:mm:ss") : "-"}
                                                </>}
                                                {!option?.type && <>
                                                    {item[dataIndex] ? item[dataIndex] : "-"}
                                                </>}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : ''
                },
            })

        const [columns, setColumns] = useState<ProColumns<DeliveryResultReport>[]>([
            {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                fixed: 'left',
                width: 50,
                align: 'center',
            },

            {
                title: 'Nhà cung cấp',
                dataIndex: 'vendorFmt',
                key: 'vendorFmt',
                width: 300,
                fixed: 'left',
            },
            {
                title: 'Mã đăng ký',
                dataIndex: 'deliveryRegistrationCode',
                key: 'deliveryRegistrationCode',
                width: 150,
                align: 'center',
                fixed: 'left',
                // render(text, record) {
                //     return <Link to={ROUTES.PURCHASE_ORDER.LIST_PO.EDIT_VOTE.LINK + '/' + record.deliveryRegistrationId}>{text}</Link>
                // },
                ...getColumnRenderProps('deliveryRegistrationCode', { type: 'link' })
            },
            {
                title: 'Mã PO',
                dataIndex: 'purchaseOrderCode',
                key: 'purchaseOrderCode',
                width: 130,
                align: 'center',
            },
            {
                title: 'PO Item',
                dataIndex: 'poItem',
                key: 'poItem',
                width: 100,
                align: 'center',
            },

            {
                title: 'Tên hàng',
                dataIndex: 'productName',
                key: 'productName',
                width: 300,
                ellipsis: true,
            },
            {
                title: 'Số lượng giao',
                dataIndex: 'slGiao',
                key: 'slGiao',
                children: [{
                    title: 'Dự kiến',
                    dataIndex: 'estimateDeliveryQuantity',
                    key: 'estimateDeliveryQuantity',
                    width: 150,
                    ...getColumnRenderProps('estimateDeliveryQuantity', { align: 'text-right', type: 'number' })
                },
                {
                    title: 'Thực tế',
                    dataIndex: 'actualDeliveryQuantity',
                    key: 'actualDeliveryQuantity',
                    width: 150,
                    ...getColumnRenderProps('actualDeliveryQuantity', { align: 'text-right', type: 'number' })
                }]
            },
            {
                title: 'Trạng thái nhận hàng',
                dataIndex: 'receiveStatus',
                key: 'receiveStatus',
                width: 150,
                ...getColumnRenderProps('receiveStatus', { type: 'tag', })
            },
            {
                title: 'Tình trạng phiếu',
                dataIndex: 'taskStatusName',
                key: 'taskStatusName',
                width: 150,
                ...getColumnRenderProps('taskStatusName', { type: 'customTag' })
            },
            {
                title: 'Thời gian giao hàng',
                dataIndex: 'deliveryDate',
                key: 'deliveryDate',
                width: 150,
                align: 'center',
                valueType: 'date',
                fieldProps: {
                    format: "DD/MM/YYYY",
                }
            },
            {
                title: 'Thời gian nhận hàng',
                dataIndex: 'receiveTime',
                key: 'receiveTime',
                width: 150,
                align: 'center',
                ...getColumnRenderProps('receiveTime', { type: 'date' })
            },
        ])

        const getDeliveryStatusColor = (status: string) => {
            switch (status) {
                case 'Nhận hàng': return 'success'
                case 'Từ chối nhận hàng': return 'error'
                default: return 'default'
            }
        }

        // mẫu báo cáo
        const tableRef = useRef<ActionType>();
        const constDataName = 'BCKetQuaGiaoHang';
        const {
            isResizablePresent,
            setIsResizablePresent,
            resizableColumnSelect,
            columnsState,
            handleColumnsStateChange,
            templateReportModalRef,
            userReports,
            selectedReportId,
            templateReportModalProps,
            handleResize,
        } = useResizableColumns<DeliveryResultReport>(columns, setColumns, constDataName);

        return (
            <PageWrapper
                breadcrumb={[{
                    text: 'Báo cáo kết quả giao hàng'
                }]}
                extras={
                    <>
                        {isExport && <ExportButton loading={loading} onClick={async () => {
                            setLoading(true);
                            const exportRequest = cloneDeep(dataPaging)
                            await reportPresenter.exportReportDeliveryResult(omit(exportRequest, ['paging']))
                            setLoading(false);
                        }} />}
                        <CustomButton
                            onClick={() => setOpenDrawer(true)}
                            type="primary" color="#EFF4F9"
                            className="!px-0 w-8"
                        >
                            <Filter className="!fill-primary" />
                        </CustomButton>
                    </>
                }
            >

                <BoxContainer>
                    <CustomProTable<DeliveryResultReport>
                        bordered
                        columns={columns}
                        dataSource={dataTable}
                        actionRef={tableRef}

                        tableAlertRender={false}
                        handleResize={handleResize}
                        isResizablePresent={isResizablePresent}
                        resizableColumnSelect={resizableColumnSelect}
                        columnsState={{
                            value: Object.keys(columnsState).length > 0 ? columnsState : undefined,
                            onChange: (newColumnsState) => {
                                handleColumnsStateChange(newColumnsState);
                            },
                        }}
                        toolBarRender={() => [

                            // nút lưu mẫu bc
                            <SaveReportForm
                                setIsResizablePresent={setIsResizablePresent}
                                templateReportModalRef={templateReportModalRef}
                                userReports={userReports}
                                selectedReportId={selectedReportId}
                                templateReportModalProps={templateReportModalProps}
                            />,
                        ]}
                        scroll={{ y: "calc(100vh - 350px)", x: "1500px" }}
                        rowKey="stt"
                        pagination={{
                            ...tablePagination(dataPaging, setDataPaging),
                            total: reportStore.deliveryResultRes?.paging?.recordsTotal,
                        }}
                        request={async (params = {}, sort, filter) => {
                            const data = await getDataTable({
                                ...dataPaging,
                                paging: {
                                    ...dataPaging.paging,
                                    orderBy:
                                        Object.values(sort)[0] === "ascend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                    orderByDesc:
                                        Object.values(sort)[0] === "descend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                },
                            });
                            if (Object.values(sort).length) {
                                setDataPaging({
                                    ...dataPaging,
                                    paging: {
                                        ...dataPaging.paging,
                                        orderBy:
                                            Object.values(sort)[0] === "ascend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                        orderByDesc:
                                            Object.values(sort)[0] === "descend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                    },
                                });
                            }
                            return {
                                data: data,
                                success: reportStore.deliveryResultRes?.isSuccess
                            };

                        }}
                    />

                </BoxContainer>
                <SearchBox
                    onClose={() => { setOpenDrawer(false) }}
                    className="lg:!w-[50%]"
                    open={openDrawer}
                    footer={
                        <CustomButton
                            htmlType="submit"
                            className="my-auto ml-auto"
                            type="primary"
                            icon={<HiSearch />}
                            loading={loading}
                            onClick={() => {
                                searchForm.submit();
                            }}
                        >
                            Tìm kiếm
                        </CustomButton>
                    }
                >
                    <SearchForm
                        form={searchForm}
                        onFinish={(values) => {
                            const valueClone = cloneDeep(values)
                            // convert lại from date to date
                            const receiveDateFrom = valueClone?.receiveDateFrom ? valueClone.receiveDateFrom?.format("YYYY-MM-DD") : undefined
                            const receiveDateTo = valueClone?.receiveDateTo ? valueClone.receiveDateTo?.format("YYYY-MM-DD") : undefined
                            const deliveryDateFrom = valueClone?.deliveryDateFrom ? valueClone.deliveryDateFrom?.format("YYYY-MM-DD") : undefined
                            const deliveryDateTo = valueClone?.deliveryDateTo ? valueClone.deliveryDateTo?.format("YYYY-MM-DD") : undefined
                            Object.assign(valueClone, { receiveDateFrom, receiveDateTo, deliveryDateFrom, deliveryDateTo })
                            // bỏ các giá trị rỗng null và trim giá trị
                            removeEmptyValues(valueClone)
                            const dataSearch: SearchDeliveryResultReport = { paging: { ...dataPaging.paging, pageIndex: 1 }, ...valueClone }
                            setDataPaging(dataSearch)
                            setOpenDrawer(false)
                            tableRef.current?.reload()
                        }}
                    >
                        {/* Mã phiếu */}
                        <SearchItem name={'deliveryRegistrationCode'} label={'Mã phiếu'}>
                            <Input />
                        </SearchItem>
                        {/* Số PO */}
                        <SearchItem name={'purchaseOrderCode'} label={'Số PO'}>
                            <Input />
                        </SearchItem>
                        {/* Mã hàng hóa */}
                        <SearchItem name={'productCode'} label={'Mã hàng hóa'}>
                            <Input />
                        </SearchItem>
                        {/* Nhà cung cấp */}
                        <SearchItem name={'vendorNumber'} label={'Nhà cung cấp'}>
                            <Select
                                popupMatchSelectWidth={false}
                                options={listVendor.map(item => ({ value: item.key, label: item.value }))}
                                allowClear showSearch
                                filterOption={false}
                                onSearch={(value) => searchVendor(value || undefined)} />
                        </SearchItem>
                        {/* Tên hàng hóa */}
                        <SearchItem name={'productName'} label={'Tên hàng hóa'}>
                            <Input />
                        </SearchItem>
                        {/* Tình trạng phiếu */}
                        <SearchItem name={'taskStatusCategory'} label={'Tình trạng phiếu'}>
                            <Select
                                options={commonStore.dropdownTaskStatus}
                                fieldNames={{ label: 'value', value: 'key' }}
                                filterOption={false}
                                allowClear
                                placeholder={'-- Tất cả --'}
                            />
                        </SearchItem>
                        {/* Trạng thái nhận hàng */}
                        <SearchItem name={'receiveStatus'} label={'Trạng thái nhận hàng'}>
                            <Select
                                fieldNames={{ label: 'value', value: 'key' }}
                                options={commonStore.dropdownReceiveStatus}
                                allowClear
                                filterOption={false}
                                placeholder={'-- Tất cả --'}
                            />
                        </SearchItem>
                        {/* Thời gian nhận hàng */}
                        <SearchItem name='common' initialValue={'Custom'} label={'Thời gian nhận hàng'} start>
                            <Select options={commonStore.listCommonDate} fieldNames={{ label: 'value', value: 'key' }} onChange={async (value) => {
                                if (value && value !== "Custom") {
                                    getDateByCommonDate(value)
                                } else {
                                    setDisableDatePicker(false)
                                    searchForm.setFieldsValue({ receiveDateFrom: undefined, receiveDateTo: undefined })
                                }
                            }} />
                        </SearchItem>

                        {/* Từ ngày */}
                        <SearchItem
                            name={'receiveDateFrom'} label={'Từ ngày'} start
                        >
                            <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>
                        {/* Đến ngày */}
                        <SearchItem name={'receiveDateTo'} label={'Đến ngày'}
                        >
                            <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>

                        {/* Thời gian giao hàng */}
                        <SearchItem name='commonDeliveryDate' initialValue={'Custom'} label={'Thời gian giao hàng'} start>
                            <Select options={commonStore.listCommonDate} fieldNames={{ label: 'value', value: 'key' }} onChange={async (value) => {
                                if (value && value !== "Custom") {
                                    getDateByCommonDateDelivery(value)
                                } else {
                                    setDisableDatePickerDelivery(false)
                                    searchForm.setFieldsValue({ deliveryDateFrom: undefined, deliveryDateTo: undefined })
                                }
                            }} />
                        </SearchItem>

                        {/* Từ ngày */}
                        <SearchItem
                            name={'deliveryDateFrom'} label={'Từ ngày'} start
                            initialValue={dayjs().subtract(1, 'month')}
                            dependencies={['deliveryDateTo']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value || getFieldValue('deliveryDateTo')) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error('Chưa nhập giá trị "Từ ngày".'));;
                                    },
                                })
                            ]}
                        >
                            <DatePicker changeOnBlur disabled={disableDatePickerDelivery} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>
                        {/* Đến ngày */}
                        <SearchItem name={'deliveryDateTo'} label={'Đến ngày'}
                            initialValue={dayjs()}
                            dependencies={['deliveryDateFrom']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value || getFieldValue('deliveryDateFrom')) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Chưa nhập giá trị "Đến ngày".'));
                                    },
                                })
                            ]}
                        >
                            <DatePicker changeOnBlur disabled={disableDatePickerDelivery} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>

                    </SearchForm>
                </SearchBox>
            </PageWrapper>
        )
    })
}

export default createDeliveryResult