import { HubConnectionBuilder } from "@microsoft/signalr"
import { BASE_API_URL } from "utils/constants";

const signalrConnection = () => {
    const URL = process.env.REACT_APP_BASE_API + '/Notification'
    const connection = new HubConnectionBuilder()
        .withUrl(URL)
        .withAutomaticReconnect()
        .build()

    connection
        .start()
        .then(() => console.log("SignalR Connected"))
        .catch((error) => {
            console.log("Failed: " + error);
        });

    // connection.on("ReceiveNotification", (message) => {
    //     // Xử lý thông báo nhận được từ backend
    //     console.log("Received Notification:", message);
    //     // Cập nhật giao diện để hiển thị thông báo cho người dùng
    // });

    return connection;
}

export default signalrConnection