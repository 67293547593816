import './styles.css';

import * as Icon from 'base/ui/components/Icons';

import { Checkbox, Divider, Form, Modal, Select, Space, Tooltip, message } from 'antd';
import { DataSearchAccount, statusList } from '../model';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { accountPresenter, accountStore, userStore } from 'stores/root_store';
import { useEffect, useRef, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import CustomSelect from 'base/ui/components/CustomSelect';
import { DataAccount } from 'services/RawData/account_services';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FaKey } from 'react-icons/fa';
import { HiSearch } from 'react-icons/hi';
import { ActionType, ProColumns } from '@ant-design/pro-components';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { GrPowerReset } from "react-icons/gr";
import { cloneDeep } from 'lodash';
import { SearchModel } from 'pages/model';

const { confirm } = Modal;

const accountPage = () => {
  return observer(() => {
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    const { t } = useTranslation();

    const pagetitle = t('permission.account.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);

    // Columns table title
    const defaultColumn: ProColumns<DataAccount>[] = [
      // stt
      {
        title: t('permission.account.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 60,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
      },
      // mã nhân viên
      // {
      //   title: t('permission.account.employee-code'),
      //   dataIndex: 'employeeCode',
      //   key: 'employeeCode',

      //   width: 150,
      //   className: '!rounded-none !py-1',
      //   fixed: 'left',
      // },
      // tên tài khoản
      {
        title: t('permission.account.username'),
        dataIndex: 'userName',
        key: 'userName',
        width: 150,
        className: '!rounded-none !py-1',
        fixed: 'left',
      },
      // Tên nhân viên/Nhà cung cấp
      {
        title: 'Tên nhân viên/Nhà cung cấp',
        dataIndex: 'employeeName',
        key: 'employeeName',
        width: 250
      },
      // Email
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200
      },
      // Số điện thoại
      {
        title: 'Số điện thoại',
        dataIndex: 'phone',
        key: 'phone',
        width: 130
      },
      // Nhóm người dùng
      {
        title: 'Nhóm người dùng',
        dataIndex: 'listRoleName',
        key: 'listRoleName',
        width: 250
      },
      // Tài khoản NCC
      {
        title: 'Tài khoản NCC',
        dataIndex: 'isSupplier',
        key: 'isSupplier',
        align: 'center',
        width: 130,
        render: (_, record) => (
          <div className="w-full flex justify-center">
            {record.isSupplier && (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            )}
          </div>
        ),
      },
      // Người tạo
      {
        title: 'Người tạo',
        dataIndex: 'createByName',
        key: 'createByName',
        width: 240,
      },
      // Thời gian tạo
      {
        title: 'Thời gian tạo',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 150,
        align: 'center',
        valueType: 'dateTime',
        fieldProps: {
          format: 'DD/MM/YYYY HH:mm:ss',
        },
      },
      // Lần đăng nhập cuối
      {
        title: 'Lần đăng nhập cuối',
        dataIndex: 'lastLogin',
        key: 'lastLogin',
        width: 150,
        align: 'center',
        valueType: 'dateTime',
        fieldProps: {
          format: 'DD/MM/YYYY HH:mm:ss',
        },
      },
      // trạng thái
      {
        title: t('permission.account.status'),
        dataIndex: 'actived',
        width: 100,
        key: 'actived',
        align: 'center',
        render: (text) => (
          <div className="w-full flex justify-center">
            {text ? (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            ) : (
              <Icon.CloseStatus width="15" className="!fill-red-500" />
            )}
          </div>
        ),
      },
      // Chức năng
      {
        title: t('permission.account.actions'),
        dataIndex: '',
        key: '',
        width: 150,
        className: '!rounded-none !py-1',
        fixed: 'right',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') ? (
                <Tooltip title={t('btn.edit')}>
                  <CustomButtonIcon color="#007BF1" onClick={() => navigate(`${record.accountId}`)}>
                    <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                  </CustomButtonIcon>
                </Tooltip>
              ) : null}
              {userStore.userPermission?.includes('DELETE') ? (
                <Tooltip title={t('btn.delete')}>
                  <CustomButtonIcon
                    color="#FC5C5E"
                    onClick={() => {
                      showDeleteConfirm(record);
                    }}
                  >
                    <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                  </CustomButtonIcon>
                </Tooltip>
              ) : null}
              {userStore.userPermission?.includes('EDIT') ? (
                <Tooltip title={t('btn.change-password')}>
                  <Link to={`ChangePassword/${record.accountId}`}>
                    <CustomButtonIcon color="#e08e0b">
                      <FaKey className="!fill-[#e08e0b]" width="14" height="14" />
                    </CustomButtonIcon>
                  </Link>
                </Tooltip>
              ) : null}
              {userStore.userPermission?.includes('RESET_PASSWORD') && (
                <Tooltip title={"Reset password"}>
                  <Link to={`ResetPassword/${record.accountId}`}>
                    <CustomButtonIcon color="#389e0d" >
                      <GrPowerReset className="text-[#389e0d]" width="14" height="14" />
                    </CustomButtonIcon>
                  </Link>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    ];
    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<DataAccount>[]>(defaultColumn);
    // Loading all page
    const [loading, setLoading] = useState(false);

    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: undefined as string | undefined,
        orderByDesc: undefined as string | undefined,
      },
    });
    const [listDataAccount, setListDataAccount] = useState<DataAccount[]>([]);
    // ref của table
    const tableRef = useRef<ActionType>();

    // function open hide drawer ===========================================================
    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Call api search Data
    const getDataSearchAccount = async (dataSearch: DataSearchAccount) => {
      await accountPresenter.searchAccount(accountStore, dataSearch);
      if (accountStore.listAccountResponse.isSuccess) {
        setListDataAccount(accountStore.listAccountResponse.data);
        const cloneData = cloneDeep(accountStore.listAccountResponse.data)
        return cloneData
      }
      return []
    };


    const getDropDownRole = async () => {
      await accountPresenter.getDropdownRoles(accountStore)
    }

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
        ...values,
      };
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);
      tableRef.current?.reload()
      setLoading(false);
      onCloseDrawer();
    };

    // scroll to top
    useEffect(() => {
      getDropDownRole()
      window.scrollTo(0, 0);
    }, []);

    // initialValues of form search
    const initialValues = {};

    // Call api delete
    const handleClickDeleteAccount = async (accountId: string) => {
      setLoading(true);
      if (accountId) {
        await accountPresenter.deleteAccountById(accountStore, accountId);
        if (accountStore.crudRes.isSuccess) {
          message.success(accountStore.crudRes.message);
          // Call list branch after change
          // await getDataSearchAccount(dataSearch);
          tableRef.current?.reload()
        } else {
          message.error(accountStore.crudRes.message);
        }
      }
      setLoading(false);
    };

    // Ui modal confirm delete store
    const showDeleteConfirm = (item: DataAccount) => {
      confirm({
        title: `${t('btn.delete')} ${item.userName}`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} ${item.userName}`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDeleteAccount(item.accountId);
        },
        onCancel() { },
      });
    };

    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/Permission/Account' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  onClick={() => {
                    navigate('create');
                  }}
                  type="primary"
                  icon={<Icon.Plus className="!fill-white" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<DataAccount>
            columns={columns}
            rowKey="stt"
            size="small"
            bordered
            search={false}
            actionRef={tableRef}
            request={async (params = {}, sort, filter) => {
              const data = await getDataSearchAccount({
                ...dataSearch,
                paging: {
                  ...dataSearch.paging,
                  orderBy:
                    Object.values(sort)[0] === "ascend"
                      ? `${Object.keys(sort)[0].split(",").pop()}`
                      : undefined,
                  orderByDesc:
                    Object.values(sort)[0] === "descend"
                      ? `${Object.keys(sort)[0].split(",").pop()}`
                      : undefined,
                },
              });
              if (Object.values(sort).length) {

                setDataSearch({
                  ...dataSearch,
                  paging: {
                    ...dataSearch.paging,
                    orderBy:
                      Object.values(sort)[0] === "ascend"
                        ? `${Object.keys(sort)[0].split(",").pop()}`
                        : undefined,
                    orderByDesc:
                      Object.values(sort)[0] === "descend"
                        ? `${Object.keys(sort)[0].split(",").pop()}`
                        : undefined,
                  },
                });
              }
              return {
                data: data,
                success: accountStore.listAccountResponse.isSuccess,
              };

            }}
            dataSource={listDataAccount}
            scroll={{ y: 500 }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: accountStore.listAccountResponse?.paging?.recordsTotal,
            }}

            options={false}
          />
        </BoxContainer>
        {/* Form search */}
        <SearchBox
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm column="col-1" initialValues={initialValues} form={form} onFinish={handleSearch}>
            {/* tên tài khoản */}
            <SearchItem label={t('permission.account.username')} name={'username'}>
              <CustomInput className="!rounded-none" />
            </SearchItem>
            {/* Tên nhân viên/Nhà cung cấp */}
            <SearchItem label={'Tên nhân viên/Nhà cung cấp'} name={'fullname'}>
              <CustomInput className="!rounded-none" />
            </SearchItem>
            {/* Tài khoản nhà cung cấp */}
            <SearchItem label={'Tài khoản nhà cung cấp'} name={'isSupplier'}>
              <CustomSelect options={[{ value: true, label: "Có" }, { value: false, label: "Không" }]} className="!rounded-none" allowClear placeholder="-- Tất cả --" />
            </SearchItem>
            {/* trạng thái */}
            <SearchItem label={t('permission.account.status')} name={'actived'}>
              <CustomSelect options={statusList} className="!rounded-none" allowClear placeholder="-- Tất cả --" />
            </SearchItem>
            {/* Nhóm người dùng */}
            <SearchItem label={"Nhóm người dùng"} name={'roleId'}>
              <CustomSelect
                mode="multiple"
                allowClear
                value={form.getFieldValue('roleId')}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                options={accountStore.dataDropdownRoles.data?.map((item) => {
                  return {
                    label: item.value,
                    value: item.key,
                  };
                })}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                      <Checkbox
                        // type="text"
                        onChange={(e) => {
                          if (e.target.checked) {
                            form.setFieldValue(
                              'roleId',
                              accountStore.dataDropdownRoles.data.map((e) => e.key),
                            );
                          } else {
                            form.setFieldValue('roleId', []);
                          }
                        }}
                      >
                        {t('permission.account.select-all')}
                      </Checkbox>
                    </Space>
                  </>
                )}
              />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default accountPage;
