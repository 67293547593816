import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { AccountDetail, DataAccount, IAccountServices } from 'services/RawData/account_services';
import { CommonModel } from 'services/RawData/commonMasterData_services';

export type accountStore = {
  // Type get list
  listAccountResponse: DataResponse<Array<DataAccount>>;
  dataDropdownRoles: DataResponse<CommonModel[]>;
  crudRes: DataResponse<any>;
  accountDetail: DataResponse<AccountDetail | undefined>;
};

export interface IAccountPresenter {
  createStore(): accountStore;
  // Get list
  getListAccount(store: accountStore, keyword: string): Promise<void>;
  // search
  searchAccount(store: accountStore, data: object): Promise<void>;
  // add account
  addAccount(store: accountStore, data: object): Promise<void>;
  // update account
  updateAccount(store: accountStore, data: object): Promise<void>;
  // get data in dropdown roles
  getDropdownRoles(store: accountStore): Promise<void>;
  // get data account detail
  getAccountById(store: accountStore, id: string): Promise<void>;
  // delete account by id
  deleteAccountById(store: accountStore, id: string): Promise<void>;
  // thay đổi mật khẩu
  changePassword(store: accountStore, data: object): Promise<void>;
  // reset password
  resetPassword(store: accountStore, data: { accountId: string; newPassword: string }): Promise<void>;
}

export function createAccountPresenter({ accountServices }: { accountServices: IAccountServices }): IAccountPresenter {
  return {
    createStore: () =>
      observable({
        listAccountResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
        dataDropdownRoles: {
          data: [],
          message: '',
          isSuccess: false,
        },
        crudRes: {
          data: [],
          message: '',
          isSuccess: false,
        },
        accountDetail: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
      }),

    // Get list account
    getListAccount: async (store, keyword) => {
      try {
        const result = await accountServices.getAccountList(keyword);
        runInAction(() => {
          store.listAccountResponse.data = result.data;
          store.listAccountResponse.isSuccess = result.isSuccess;
          store.listAccountResponse.message = result.message;
          store.listAccountResponse.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // search account
    searchAccount: async (store, data) => {
      try {
        const result = await accountServices.searchAccount(data);
        runInAction(() => {
          store.listAccountResponse.data = result.data;
          store.listAccountResponse.isSuccess = result.isSuccess;
          store.listAccountResponse.message = result.message;
          store.listAccountResponse.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get data in dropdown roles
    getDropdownRoles: async (store) => {
      try {
        const result = await accountServices.getDropdownRoles();
        runInAction(() => {
          store.dataDropdownRoles.data = result.data;
          store.dataDropdownRoles.isSuccess = result.isSuccess;
          store.dataDropdownRoles.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // add account
    addAccount: async (store, data) => {
      try {
        const result = await accountServices.addAccount(data);
        runInAction(() => {
          store.crudRes.isSuccess = result.isSuccess;
          store.crudRes.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // update account
    updateAccount: async (store, data) => {
      try {
        const result = await accountServices.updateAccount(data);
        runInAction(() => {
          store.crudRes.isSuccess = result.isSuccess;
          store.crudRes.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get data account detail
    getAccountById: async (store, id) => {
      try {
        const result = await accountServices.getAccountById(id);
        runInAction(() => {
          store.accountDetail.data = result.data;
          store.accountDetail.isSuccess = result.isSuccess;
          store.accountDetail.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // delete account by id
    deleteAccountById: async (store, id) => {
      try {
        const result = await accountServices.deleteAccountById(id);
        runInAction(() => {
          store.crudRes.isSuccess = result.isSuccess;
          store.crudRes.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get data account detail
    changePassword: async (store, data) => {
      try {
        const result = await accountServices.changePassword(data);
        runInAction(() => {
          store.crudRes.isSuccess = result.isSuccess;
          store.crudRes.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // reset password
    resetPassword: async (store, data) => {
      try {
        const result = await accountServices.resetPassword(data);
        runInAction(() => {
          store.crudRes.isSuccess = result.isSuccess;
          store.crudRes.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
