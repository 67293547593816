import * as Icon from 'base/ui/components/Icons';

import { Form, Modal, Tooltip } from 'antd';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  userStore,
  vendorPresenter,
  vendorStore,
} from 'stores/root_store';
import { PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';

import { ActionType, ProColumns } from '@ant-design/pro-components';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { HiOutlineUserAdd, HiSearch, HiUserAdd } from 'react-icons/hi';
import { DataSearchVendor } from '../model';
import { SearchModel } from 'pages/model';
import { VendorModel } from 'services/RawData/vendor_services';
import { statusList } from 'pages/rawData/Permission/model';
import { usePermission } from 'hooks/usePermission';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { checkPermissionWithId } from 'utils/permissionHelper';

const { confirm } = Modal;

// Nhà cung cấp
const vendorPage = () => {
  return observer(() => {
    const { t } = useTranslation();

    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
    const isCreate = checkPermissionWithId("CREATE")


    const pagetitle = t('masterdata.vendor.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const [form] = Form.useForm();
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);
    // Columns table title
    const defaultColumn: ProColumns<VendorModel>[] = [
      // Stt
      {
        title: t('masterdata.vendor.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 30,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
      },
      // Mã ncc
      {
        title: t('masterdata.vendor.vendor-code'),
        dataIndex: 'supplierNumber',
        key: 'supplierNumber',
        width: 50,
        className: '!rounded-none !py-1',
        fixed: 'left',
        ellipsis: true,
        render: (_, record) => {
          return userStore.userPermission?.includes('EDIT') ? (
            <Link to={`/MasterData/Vendor/${record.vendorId}`}>{record.supplierNumber}</Link>
          ) : (
            <div>{record.supplierNumber}</div>
          );
        },
      },
      // tên ncc
      {
        title: t('masterdata.vendor.full-name'),
        dataIndex: 'longName',
        key: 'longName',
        width: 200,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Địa chỉ
      {
        title: t('masterdata.vendor.address'),
        dataIndex: 'address',
        key: 'address',
        width: 250,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },

      // Điện thoại
      {
        title: t('masterdata.vendor.phone'),
        dataIndex: 'telephone',
        key: 'telephone',
        width: 40,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Email
      {
        title: "Email",
        dataIndex: 'email',
        key: 'email',
        width: 80,
      },
      // Ngày tạo
      {
        title: 'Ngày tạo',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 60,
        align: 'center',
        valueType: 'dateTime',
        fieldProps: {
          format: 'DD/MM/YYYY HH:mm:ss',
        },
      },

      {
        title: t('masterdata.vendor.status'),
        dataIndex: 'actived',
        width: 40,
        key: 'actived',
        ellipsis: true,
        align: 'center',
        render: (text, record) => (
          <div className="w-full flex justify-center">
            {record.actived ? (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            ) : (
              <Icon.CloseStatus width="15" className="!fill-red-500" />
            )}
          </div>
        ),
      },
      {
        title: "Chức năng",
        width: 40,
        key: 'action',
        align: 'center',
        hideInTable: isCreate ? false : true,
        hideInSetting: isCreate ? false : true,
        render: (_, record) => (
          <div className='w-full '>
            {!record.isCreateAccount && (
              <Tooltip title={"Tạo tài khoản"}>
                <Link to={`/Permission/Account/CreateVendorAccount/${record.supplierNumber}?VendorName=${record.longName}`}>
                  <CustomButtonIcon color="#3b82f6" className='mx-auto'>
                    <HiUserAdd className='text-blue-500 text-sm' />
                  </CustomButtonIcon>
                </Link>
              </Tooltip>
            )}
          </div>
        ),
      },

    ];

    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<VendorModel>[]>(defaultColumn);
    // Loading all page
    const [loading, setLoading] = useState(false);

    const tableRef = useRef<ActionType>();

    // ====================SEARCH DROPDOWN=========================================

    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    });

    // List data company
    const [listSalesEmployee, setListSalesEmployee] = useState<VendorModel[]>([]);

    // Function open hide drawer
    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Call api search data
    const getDataSearchVendor = async (dataSearch: DataSearchVendor) => {
      await vendorPresenter.searchVendor(vendorStore, dataSearch);
      if (vendorStore.searchVendorResponse.isSuccess) {
        setListSalesEmployee(vendorStore.searchVendorResponse.data);
      }
    };

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      // set pageIndex 1 when search form
      setDataSearch({
        // loại bỏ những field không có giá trị
        // ...Object.fromEntries(Object.entries(dataResult).filter(([_, value]) => !!value)),
        ...values,
        paging: {
          pageSize: 10,
          pageIndex: 1,
        },
      });
      tableRef.current?.reload()
      onCloseDrawer();
      setLoading(false);
    };

    // initialValues of form search
    const initialValues = {};

    // ================================ CALL DATA ==========================================
    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);


    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/Masterdata/SalesEmployee' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<VendorModel>
            columns={columns}
            rowKey="stt"
            size="small"
            bordered
            actionRef={tableRef}
            search={false}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const data: any = await getDataSearchVendor({
                ...dataSearch,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  ...dataSearch,
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: vendorStore.searchVendorResponse.isSuccess,
              };
            }}
            dataSource={listSalesEmployee}
            scroll={{ x: 2000, y: 500 }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: vendorStore.searchVendorResponse?.paging?.recordsTotal,
            }}

            options={false}
          />
        </BoxContainer>

        <SearchBox // tìm kiếm
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm
            initialValues={initialValues}
            form={form}
            onFinish={(values) => {
              handleSearch(values);
            }}
            layout="vertical"
            column="col-1"
          >
            {/* mã nhân viên */}
            <SearchItem label={t('masterdata.vendor.vendor-code')} name={'supplierNumber'}>
              <CustomInput />
            </SearchItem>
            {/* họ và tên */}
            <SearchItem label={t('masterdata.vendor.full-name')} name={'supplierName'}>
              <CustomInput />
            </SearchItem>
            {/* Trạng thái */}
            <SearchItem label={'Trạng thái'} name={'actived'}>
              <CustomSelect options={statusList} className="!rounded-none" allowClear placeholder="-- Tất cả --" />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default vendorPage;
