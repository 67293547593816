import accountPage from '.';
import createAddUpdateAccount from './CreateUpdate';
import createChangePassword from './ChangePW';
import { accountRoutes } from './routes';
import createResetPassword from './ResetPassword';
import createAddVendorAccount from './CreateVendorAccount';

export const createAccountPage = () => {
  const Account = accountPage();
  const AddAccount = createAddUpdateAccount();
  const UpdateAccount = createAddUpdateAccount();
  const ChangePassword = createChangePassword();
  const ResetPassword = createResetPassword();
  const CreateVendorAccount = createAddVendorAccount()

  const AccountPage = accountRoutes;
  return () => (
    <AccountPage
      Account={Account}
      AddAccount={AddAccount}
      UpdateAccount={UpdateAccount}
      ChangePassword={ChangePassword}
      ResetPassword={ResetPassword}
      CreateVendorAccount={CreateVendorAccount}
    />
  );
};
