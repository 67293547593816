/* eslint-disable react-hooks/exhaustive-deps */
import * as Icon from 'base/ui/components/Icons';

import { Form, Spin, message } from 'antd';
import { SearchForm } from 'pages/Components/SearchBox';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { accountPresenter, accountStore, userStore } from 'stores/root_store';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import { usePermission } from 'hooks/usePermission';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { SYSTEM_TITLE } from 'utils/constants';
import { cloneDeep } from 'lodash';
import CreateUpdateAccountForm from './CreateUpdateForm'

const createAddUpdateAccount = () => {
  return observer(() => {
    const { t } = useTranslation();
    // Get data params from url
    const { id: idParams } = useParams();
    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }
    const navigate = useNavigate();
    // Check button save and save continue
    let isSaveAndContinue = false;

    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);

    const [isVendor, setIsVendor] = useState(false);

    // =============================================================================================================
    //==========================FUNCTION===================//
    // Call api get detail in store
    const getDetailAccountById = async (accountId: string) => {
      await accountPresenter.getAccountById(accountStore, accountId);
      if (accountStore.accountDetail.isSuccess && accountStore.accountDetail.data) {
        const detail = cloneDeep(accountStore.accountDetail.data)
        // Object.assign(detail, { confirm: detail?.password })
        if (detail.isSupplier) {
          setIsVendor(true)
        }
        form.setFieldsValue(detail);
        setLoading(false);
      } else {
        navigate('/Permission/Account');
      }
    };

    // Handle create
    const onFinish = async (values: any) => {
      setLoading(true);
      if (!idParams) {
        await accountPresenter.addAccount(accountStore, {
          ...values,
        });
        if (accountStore.crudRes.isSuccess) {
          message.success(accountStore.crudRes.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/Permission/Account');
          }
          form.resetFields();
        } else {
          message.error(accountStore.crudRes.message);
        }
      } else {
        await accountPresenter.updateAccount(accountStore, {
          ...values,
          accountId: idParams,
        });
        if (accountStore.crudRes.isSuccess) {
          message.success(accountStore.crudRes.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/Permission/Account');
          }
        } else {
          message.error(accountStore.crudRes.message);
        }
      }
      setLoading(false);
    };

    useEffect(() => {
      (async () => {
        setLoading(true);
        await Promise.allSettled([
          idParams && getDetailAccountById(idParams)
          // getDropdownRoles()
        ])
        setLoading(false);
      })();
    }, []);

    const initialValues = {
      actived: true,
    };
    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }
    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('permission.account.title'),
                link: '/Permission/Account',
              },
              {
                title: pageTitle,
                link: idParams ? `/Permission/Account/${idParams}` : '/Permission/Account/create',
              },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                  {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                </div>
              </>
            }
          />

          <BoxContainer fullHeight>
            <Spin spinning={loading}>
              <SearchForm onFinish={onFinish}
                layout='horizontal'
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 13 }}
                autoComplete='off'
                initialValues={idParams ? {} : initialValues} form={form}>
                <CreateUpdateAccountForm
                  form={form}
                  idParams={idParams}
                  isUpdateVendor={isVendor}
                />

              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateAccount;
