export interface Paging {
  searchTerm?: string;
  pageSize: number;
  pageIndex: number;
  orderBy?: string;
  orderByDesc?: string;
}

export interface DataSearchRoles {
  paging?: Paging;
  roleName?: string;
}

export type DataSearchAccount = Partial<{
  paging: Paging;
  username: string;
  fullname: string;
  actived: boolean;
  roleId: string[];
}>;

export type DataSearchPage = Partial<{
  screenName: string;
  actived: boolean;
}> & {
  paging: Paging;
};

export type DataSearchFunction = Partial<{
  functionName: string;
}> & {
  paging: Paging;
};

export interface StatusModel {
  key: string;
  label: string;
  value: string;
}

export const statusList = [
  {
    label: 'Đang sử dụng',
    value: true,
  },
  {
    label: 'Ngưng sử dụng',
    value: false,
  },
];

export const callStatusList = [
  {
    label: 'Tất cả',
    value: null,
  },
  {
    label: 'Trả lời',
    value: 'ANSWERED',
  },
  {
    label: 'Không trả lời',
    value: 'NO ANSWER',
  },
  {
    label: 'Gọi nhỡ',
    value: 'MISSED',
  },
];

export const typeList = [
  {
    label: 'Tất cả',
    value: null,
  },
  {
    label: 'Gọi vào',
    value: 'inbound',
  },
  {
    label: 'Gọi ra',
    value: 'outbound',
  },
];

// loại quận huyện
export const TypeCommonDistrict = [
  {
    label: 'Thành Phố',
    value: 'Thành Phố',
  },
  {
    label: 'Quận',
    value: 'Quận',
  },
  {
    label: 'Huyện',
    value: 'Huyện',
  },
  {
    label: 'Thị Xã',
    value: 'Thị Xã',
  },
];
// loại thị xã
export const TypeCommonWard = [
  {
    label: 'Phường',
    value: 'Phường',
  },
  {
    label: 'Xã',
    value: 'Xã',
  },
  {
    label: 'Thị trấn',
    value: 'Thị trấn',
  },
];
